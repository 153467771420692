import { Box, TablePagination, Typography } from "@mui/material";
import React, { useState } from "react";
import { checkSessionField } from "../../Utils/ExhibitionsUtils";

function SessionField(props) {
  const {
    index,
    currentSession,
    element,
    handleClickParticipant,
    handleClickPeople,
    handleImage,
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  function handleChangePage(event, newPage) {
    setPage(newPage);
  }
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  const isExhibits = !!currentSession[element] && Array.isArray(currentSession[element]) && !!currentSession[element][0]?.exhibits

  function _filter_array_for_pagination(raw_array, page, perPage) {
    const current_max_index = page * perPage + perPage;
    const { exhibits, ...other } = isExhibits && raw_array[0]
    const array = isExhibits ? [...raw_array[0].exhibits].sort((a, b) => a.number_in_catalog - b.number_in_catalog) : raw_array;
    if (Array.isArray(array) && array.length > 0) {
      const filtered = array.filter(
        (el, ind) =>
          current_max_index - perPage <= ind && ind <= (current_max_index - 1)
      )
      return isExhibits ? [{
        ...other,
        exhibits: [...filtered]
      }] : filtered
    } else return raw_array;
  }
  console.log("______", element, currentSession, currentSession[element])
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        width: "100%",
        gap: "8px",
        p: {
          xs: "8px",
          sm: "8px 0",
        },
      }}
    >
      <Typography
        variant="advanced_caption"
        color="initial"
        sx={{
          pl: {
            xs: 0,
            sm: 2,
          },
          maxWidth: "260px",
          minWidth: "260px",
          fontSize: {
            xs: "14px",
            md: "inherit",
          },
        }}
      >
        {index}{index.indexOf('Дата') >= 0 ? <><br />(совр./юл.)</> : ""}
      </Typography>
      <Box
        color="initial"
        sx={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          width: '100%',
          "& >.MuiTypography-root": {
            fontSize: {
              xs: "14px",
              md: "20px",
            },
            maxWidth: "800px",
          },
        }}
      >
        {typeof currentSession[element] === "string" && (
          <Typography variant="exh_text">
            {currentSession[element].length > 0 ? currentSession[element] : "-"}
          </Typography>
        )}
        {typeof currentSession[element] === "number" && (
          <Typography variant="exh_text">{currentSession[element]}{"/" + (currentSession[element.split('Year').join(('YearOldStyle'))] || "-")}</Typography>
        )}
        {typeof currentSession[element] === "object" &&
          checkSessionField(
            _filter_array_for_pagination(
              currentSession[element],
              page,
              rowsPerPage
            ),
            element,
            currentSession.city,
            handleClickParticipant,
            handleClickPeople,
            handleImage,
            page * rowsPerPage
          )}
        {typeof currentSession[element] === "object" &&
          (currentSession[element]?.length > 10 || (isExhibits && currentSession[element][0]?.exhibits?.length > 10)) && (
            <TablePagination
              sx={{
                opacity: 0.8,
                "& .MuiToolbar-root": {
                  flexWrap: { xs: "wrap", md: "nowrap" },
                  justifyContent: { xs: "center", md: "inherit" },
                },
              }}
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={isExhibits ? currentSession[element][0].exhibits.length : currentSession[element].length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Показывать по:"}
              labelDisplayedRows={function defaultLabelDisplayedRows({
                from,
                to,
                count,
              }) {
                return `${from}–${to} из ${count !== -1 ? count : `следующие ${to}`
                  }`;
              }}
              getItemAriaLabel={function defaultGetAriaLabel(type) {
                return `Показать ${type} страницу`;
              }}
            />
          )}
      </Box>
    </Box>
  );
}

export default SessionField;
