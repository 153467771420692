import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resultDataSet } from "../../Utils/ExhibitionsUtils";
import SessionListDialog from "../Dialogs/SessionListDialog";
import LoadingProgress from "../Blocks/LoadingProgress";

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: "title",
    numeric: false,
    label: "Название выставки",
  },
  {
    id: "start_year",
    numeric: true,
    label: "Год начала \b(совр/юл.)",
  },
  {
    id: "finish_year",
    numeric: true,
    label: "Год окончания \b(совр/юл.)",
  },
  // {
  //   id: "old_style_start_year",
  //   numeric: true,
  //   label: "Год начала юл.",
  // },
  // {
  //   id: "old_style_finish_year",
  //   numeric: true,
  //   label: "Год окончания юл.",
  // },
  // {
  //   id: 'city_list',
  //   numeric: false,
  //   label: 'Города проведения выставок',
  // }
];
function ResultTable() {
  const loading = useSelector((state) => state.exhi.loading);
  const responseData = useSelector((state) => state.exhi.responseData);
  const currentFilters = useSelector((state) => state.exhi.currentFilters);
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("start_year");
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [withNegative, setWithNegative] = useState(false);
  const [currentRow, setCurrentRow] = useState([]);
  const [extraHeader, setExtraHeader] = useState([]);
  
  const isCurrentFiltersHasNegative = currentFilters.filter(
    (filt) => filt.label === "sessionCity" && filt.isNegative
  );
  
  const handleClickOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  useEffect(() => {
    setWithNegative(isCurrentFiltersHasNegative.length>0)
  }, [isCurrentFiltersHasNegative]);

  useEffect(() => {
    setRows(resultDataSet(responseData));
  }, [responseData]);

  useEffect(() => {
    if (currentRow.length > 0) handleClickOpenModal();
  }, [currentRow]);

  useEffect(() => {
    if (currentFilters) {
      let arr = [];
      currentFilters.map((filter, index) => {
        const search = arr.find((elem) => elem.id === filter.label) || null;
        if (!search) {
          switch (filter.label) {
            case "exhType":
              if (!!arr.find((column) => column.id === filter.label)) break;
              arr.push({
                id: "exhType",
                numeric: true,
                label: "Тип выставки",
              });
              break;
            case "exhKind":
              if (!!arr.find((column) => column.id === filter.label)) break;
              arr.push({
                id: "exhKind",
                numeric: true,
                label: "Вид искусства",
              });
              break;
            case "exhOrgName":
              if (!!arr.find((column) => column.id === "organizations")) break;
              arr.push({
                id: "organizations",
                numeric: true,
                label: "Институция - организаторы выставки",
              });
              break;
            case "sessionPlace":
              if (!!arr.find((column) => column.id === filter.label)) break;
              arr.push({
                id: "sessionPlace",
                numeric: true,
                label: "Место проведенния сессии",
              });
              break;
            case "sessionAddress":
              if (!arr.find((column) => column.id === filter.label)) break;
              arr.push({
                id: "sessionAddress",
                numeric: true,
                label: "Адрес проведенния сессии",
              });
              break;
            default:
              break;
          }
        }
      });
      setExtraHeader([
        ...arr,
        {
          id: "city_list",
          numeric: false,
          label: "Город проведения сессии",
        },
      ]);
    }
  }, [currentFilters]);

  const hasNegativeMark = (row, filtersList) => {
    let hasNegative = false;
    if (isCurrentFiltersHasNegative.length > 0) {
      let textArr = [];
      isCurrentFiltersHasNegative.map((negFilter, index) => {
        textArr.push(negFilter.text);
      });
      textArr.map((filterText) => {
        const result = row.city_list.find(
          (el, ind) =>
            String(el).toLowerCase() === String(filterText).toLowerCase()
        );
        if (result) {
          hasNegative = true;
        } 
      });
    }
    return hasNegative;
  };

  const handleOpenOneExhibition = (event) => {
    let oneExh = responseData.filter(
      (exh) => exh.exhibition_id === +event.currentTarget.id
    );
    navigate(`/one-exhibit/${event.currentTarget.id}`, {
      replace: false,
      state: { oneExh: { ...oneExh[0] } },
    });
  };

  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };
  if (loading) return <LoadingProgress />;
  const exhTotal = stableSort(rows, getComparator(order, orderBy)).filter(el=>el.title.indexOf("Тест: ")!==0).length;
  if (exhTotal===0) return <Box display={'flex'} justifyContent={"center"}><Typography variant="person_label">Ничего не найдено, подпробуйте изменить запрос</Typography></Box>
  return (
    <TableContainer
      sx={{
        maxWidth: "100%",
        display: { xs: "none", sm: "block" },
      }}
    >
      {exhTotal > 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            flexDirection:'column',
            pb: 2,
          }}
        >
          <Typography variant="capture">
            Найдено выставок - {exhTotal}
          </Typography>
          {
            withNegative && (
              <Box className="hasNegative" sx={{p:"8px 4px"}}>
              <Typography variant="capture">
                Выставки включают в себя город исключение
              </Typography>
              </Box>
            )
          }
        </Box>
      )}

      <Table
        sx={{ minWidth: 650, pb: 10 }}
        // size="small"
        stickyHeader
        aria-label="exhi-result-table"
      >
        <TableHead>
          <TableRow
            sx={{
              "& .MuiTableCell-root": {
                backgroundColor: "rgba(192,2,1, 0.1)",
                minHeight: "60px",
                fontSize: { xs: "14px", sm: "14px", md: "16px" },
                fontWeight: 400,
                // textTransform:'uppercase',
                // color:'rgba(131, 3, 2, 1)'
              },
            }}
          >
            {[...headCells, ...extraHeader].map((headCell) => (
              <TableCell
                key={headCell.id}
                id={headCell.id}
                align={headCell.numeric ? "center" : "left"}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{
                  minWidth: '140px'
                }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  sx={{
                    width:headCell.numeric ? "140px" : "inherit",
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody key={"table-body"}>
          {stableSort(rows, getComparator(order, orderBy)).map(
            (row, indRow) => {
              return (
                <TableRow
                  key={row.exhibition_id}
                  hover={true}
                  onClick={handleOpenOneExhibition}
                  id={row.exhibition_id}
                  className={hasNegativeMark(row, currentFilters)?"hasNegative":"clearRow"}
                  sx={{
                    backgroundColor: "rgba(255,255,255,1)",
                    // padding: "16px 0",
                    "&:last-child td, &:last-child th": { border: 0 },
                    "& *": { cursor: "pointer" },
                    "& .MuiTableCell-root": {
                      fontSize: { xs: "14px", sm: "14px", md: "20px" },
                      color: "rgba(0,0,0,0.6)",
                    },
                  }}
                >
                  {[...headCells, ...extraHeader].map((element, index) => {
                    if (typeof row[element.id] === "object") {
                      if (
                        element.id === "exhKind" ||
                        element.id === "exhType"
                      ) {
                        return (
                          <TableCell key={index} id={element.id} size="small">
                            {row[element.id].join(", ")}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={index}>
                          <Grid container>
                            {row[element.id].filter((el)=>!!el).map((el, ind,array) => {
                              const isLast = ind + 1 === array.length;
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  key={el + ind}
                                  sx={{
                                    // minHeight: "52px",
                                    // maxHeight: "52px",
                                    display: "flex",
                                    borderBottom: isLast
                                      ? "none"
                                      : "1px solid rgba(0, 0, 0, 0.212)",
                                    "& p": {
                                      p: "6px 0",
                                      m: 0,
                                      lineHeight: "14px",
                                    },
                                  }}
                                >
                                  <p>{el}</p>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          key={index}
                          // size="small"
                          align={element.id === "title" ? "left" : "center"}
                          id={element.id}
                        >
                          {row[element.id]}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
      <SessionListDialog
        key={"SessionListDialog"}
        open={open}
        handleClickOpen={handleClickOpenModal}
        handleClose={handleCloseModal}
        currentRow={currentRow}
      />
    </TableContainer>
  );
}

export default ResultTable;
