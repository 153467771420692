import { Route, Routes } from "react-router-dom";
import MainWrapperPage from "./Pages/MainWrapperPage";
import StartPage from "./Pages/StartPage";
import NewsPage from "./Pages/NewsPage";
import AboutPage from "./Pages/AboutPage";
import ExhibitionsPage from "./Pages/ExhibitionsPage";
import BibliographyPage from "./Pages/BibliographyPage";
import HowToSearchPage from "./Pages/HowToSearchPage";
import { ThemeProvider } from "@mui/material";
import OneExhibitionPage from "./Pages/OneExhibitionPage";
import AdvancedSearchPage from "./Pages/AdvancedSearchPage";
import ZoomImage from "./Components/Dialogs/ZoomImage";
import { theme } from "./Theme";
import SessionPage from "./Pages/SessionPage";
import ManualPage from "./Pages/ManualPage";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<MainWrapperPage />}>
          <Route index element={<StartPage />} />
          <Route path="exhibitions" element={<ExhibitionsPage />} />
          <Route path="advanced" element={<AdvancedSearchPage />} />
          <Route path="results" element={<ExhibitionsPage />} />
          <Route path="one-exhibit/:id" element={<OneExhibitionPage />} >
          </Route>
          <Route path="one-exhibit/:id/session/:id" element={<SessionPage/>}/>
          <Route path="images" element={<ZoomImage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="news" element={<NewsPage />} />
          <Route path="biblio" element={<BibliographyPage />} />
          <Route path="manual" element={<ManualPage />} />
          <Route path="help" element={<HowToSearchPage />} />
        </Route>
      </Routes>
   </ThemeProvider>
  );
}

export default App;
