import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function ButtonStartPage({id}) {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate(`/one-exhibit/${id}`)
    }
  return (
        <Button 
            variant="contained" 
            color="primary" 
            sx={{
                fontSize:  {xs:"14px",sm:"14px",md:"24px"},
                color:{xs:'white',sm:'white',md:'rgb(135,2,3)'},
                backgroundColor:{xs:'rgb(135,2,3)',sm:'rgb(135,2,3)',md:'rgba(135,2,3,0)'}
            }}
            onClick={handleClick}
        >
          Перейти к выставке
        </Button>
  )
}

export default ButtonStartPage