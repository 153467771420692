export const INITIAL_FORM_STATE = {
  query: "",
  exhName:  [
    // {
    //   text: "",
    //   isNegative: false,
    // },
  ],
  exhYear: [
    // {
    //   exhYearStart: "",
    //   exhYearFinish: "",
    // },
  ],
  exhType: [
    // {
    //   text: "",
    //   isNegative: false,
    // },
  ],
  exhKind:  [
    // {
    //   text: "",
    //   isNegative: false,
    // },
  ],
  exhOrgName:  [
    // {
    //   text: "",
    //   isNegative: false,
    // },
  ],
  isEdition: false,
  isImg: false,
  sessionCity:   [
    // {
    //   text: "",
    //   isNegative: false,
    // },
  ],
  sessionPlace:   [
    // {
    //   text: "",
    //   isNegative: false,
    // },
  ],
  sessionAddress:   [
    // {
    //   text: "",
    //   isNegative: false,
    // },
  ],
  sessionYearStart: "",
  sessionYearFinish: "",
  personF:   [
    // {
    //   text: "",
    //   isNegative: false,
    // },
  ],
  personI:   [
    // {
    //   text: "",
    //   isNegative: false,
    // },
  ],
  personYearBirth: [
    // {
    //   personYearBirthStart:"",
    //   personYearBirthFinish:"",
    // }
  ],
  personYearDeath: [
    // {
    //   personYearDeathStart:"",
    //   personYearDeathFinish:"",

    // }
  ],
  personNationality:   [
    // {
    //   text: "",
    //   isNegative: false,
    // },
  ],
  personCitizen:   [
    // {
    //   text: "",
    //   isNegative: false,
    // },
  ],
  personGender:   [
    // {
    //   text: "",
    //   isNegative: false,
    // },
  ],
  personExhRole:   [
    // {
    //   text: "",
    //   isNegative: false,
    // },
  ],
  personSpeciality:   [
    // {
    //   text: "",
    //   isNegative: false,
    // },
  ],
};