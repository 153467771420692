import { Box, Typography } from '@mui/material'
import React from 'react'

function HowToSearchPage() {
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100%",
        display:'flex',
        alignItems:'center',
        flexDirection:'column',
        justifyContent:'center'
      }}
    >
      <Typography
        variant="h3"
        sx={{
        }}
      >
        HowToSearchPageContent
      </Typography>
    </Box>
  )
}

export default HowToSearchPage