import { IconButton, SvgIcon, Tooltip } from "@mui/material";
import React from "react";
import ChatIcon from '@mui/icons-material/Chat';
function ButtonFeedbackNavMenu({ handleOpen, id }) {
  return (
    <Tooltip title={"Отправить сообщение разработчикам"}>
      <IconButton
        id={id}
        key={"feedback-button"}
        onClick={handleOpen}
        sx={{
          position:{xs:'absolute', md:'static'},
          top:'6.5vh',
          right:"1.2vh",
          borderRadius: 0,
            color:'white',
            opacity:1,
        }}
      >
        <ChatIcon/>
      </IconButton>
    </Tooltip>
  );
}

export default ButtonFeedbackNavMenu;
