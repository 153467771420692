import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import FieldArrayPush from "./FieldArrayPush";

function AccordionFormItemsList({ values, handleClose }) {
  const labelList = {
    query: {
      label: ["Полнотекстовый поиск"],
      variable: ["query"],
      type: ["query"],
    },
    exh: {
      label: [
        "По названиям выставок",
        "По годам",
        "По типам выставок",
        "По видам искусства",
        "По институциям",
        //   "Только с изданиями",
        //   "Только с изображениями",
      ],
      variable: [
        "exhName",
        "exhYear",
        "exhType",
        "exhKind",
        "exhOrgName",
        //   "isEdition",
        //   "isImg",
      ],
      type: [
        "text",
        "year",
        "text",
        "text",
        "text",
        //  "checkbox", "checkbox"
      ],
    },
    place: {
      label: [
        "Город",
        "Место",
        "Адрес",
        // "Год начала",
        // "Год окончания"
      ],
      variable: [
        "sessionCity",
        "sessionPlace",
        "sessionAddress",
        // "sessionYearStart",
        // "sessionYearFinish",
      ],
      type: [
        "text",
        "text",
        "text",
        //  "year",
        //  "year"
      ],
    },
    member: {
      label: [
        "Фамилия",
        "Имя",
        "Год рождения",
        "Год смерти",
        "Национальность",
        "Гражданство",
        "Пол",
        "Роль в выставке",
        "Специализация",
      ],
      variable: [
        "personF",
        "personI",
        "personYearBirth",
        "personYearDeath",
        "personNationality",
        "personCitizen",
        "personGender",
        "personExhRole",
        "personSpeciality",
      ],
      type: [
        "text",
        "text",
        "year",
        "year",
        "text",
        "text",
        "dropdown",
        "text",
        "text",
      ],
    },
  };
  return (
    <Box
      sx={{
        pr: 5,
        backgroundColor: "inherit",
        minHeight: "100%",
        minWidth: "100%",
        "& *": {
          backgroundColor: "inherit",
          color:'rgb(135, 2, 3)'
        },
        "&>.MuiPaper-root": {
          boxShadow: "none",
          borderBottom: "none",
          backgroundColor: "rgba(195,137,89,0)",
        },
      }}
    >
      <Accordion disableGutters defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel-exh-header1"
        >
          <Typography variant="h6" sx={{ fontWeight: 400 }}>
            Выставки
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {labelList.exh.label.map((el, index) => (
            <FieldArrayPush
             key={"cover-wrap-exhi-"+el+labelList.exh.variable[index]}
              handleClose={handleClose}
              values={values}
              label={el}
              variable={labelList.exh.variable[index]}
              variant={labelList.exh.type[index]}
            />
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel-place-header2"
        >
          <Typography variant="h6" sx={{ fontWeight: 400 }}>
            Места проведения
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {labelList.place.label.map((el, index) => (
            <FieldArrayPush
             key={"cover-wrap-palces-"+el+labelList.exh.variable[index]}
              handleClose={handleClose}
              values={values}
              label={el}
              variable={labelList.place.variable[index]}
              variant={labelList.place.type[index]}
            />
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel-member-header3"
        >
          <Typography variant="h6" sx={{ fontWeight: 400 }}>
            Участники
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {labelList.member.label.map((el, index) => (
            <FieldArrayPush
             key={"cover-wrap-members-"+el+labelList.exh.variable[index]}
            handleClose={handleClose}
              values={values}
              label={el}
              variable={labelList.member.variable[index]}
              variant={labelList.member.type[index]}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default AccordionFormItemsList;
