import { Grid, Typography } from "@mui/material";
import React from "react";

function PersonResultMobileCards({
  session,
  handleMobileOpenOneSession,
  index,
}) {
  const handleOpen = () => {
    handleMobileOpenOneSession(index);
  };
  return (
    <Grid
      item
      xs={10}
      sm={11}
      id={"oneSessionCard" + session.session_id + index}
      key={"oneSessionCard" + session.session_id + index}
      onClick={handleOpen}
      sx={{
        maxWidth:'100%',
        minWidth:'100%',
        border: "1px solid rgba(135,2,3,1)",
        backgroundColor: "rgba(255,255,255,0.9)",
        borderRadius: "4px",
        p: 1,
        display: "flex",
        gap: 1,
        flexDirection: "column",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(255,255,255,0.2)",
        },
      }}
    >
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Grid item>
          <Typography variant="mobile_card_label" sx={{ lineHeight: 1.2 }}>
            Город выставочной сессии:
          </Typography>
          <Typography variant="mobile_card_text" sx={{ lineHeight: 1.2 }}>
            {session.city}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="mobile_card_label" sx={{ lineHeight: 1.2 }}>
            Год начала/конца:
          </Typography>
          <Typography variant="mobile_card_text" sx={{ lineHeight: 1.2 }}>
            {session.sessionYearStart}-{session.sessionYearEnd}
          </Typography>
        </Grid>
        {session.people.length + session.participants.length > 0 && (
          <Grid item>
            <Typography variant="mobile_card_label" sx={{ lineHeight: 1.2 }}>
              Количество участников:
            </Typography>
            <Typography variant="mobile_card_text" sx={{ lineHeight: 1.2 }}>
              {session.people.length + session.participants.length}
            </Typography>
          </Grid>
        )}
        {session.editions.length > 0 && (
          <Grid item>
            <Typography variant="mobile_card_label" sx={{ lineHeight: 1.2 }}>
              Количество изданий:
            </Typography>
            <Typography variant="mobile_card_text" sx={{ lineHeight: 1.2 }}>
              {session.editions.length}
            </Typography>
          </Grid>
        )}
        {session.images.length > 0 && (
          <Grid item>
            <Typography variant="mobile_card_label" sx={{ lineHeight: 1.2 }}>
              Количество изображений:
            </Typography>
            <Typography variant="mobile_card_text" sx={{ lineHeight: 1.2 }}>
              {session.images.length}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default PersonResultMobileCards;
