import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import ExhibitModal from '../Popovers/ExhibitModal';

let varList = {
    number_in_catalog:"№ в каталоге",
    author_in_catalog:"Автор, \bкак в каталоге",
    showpiece_in_catalog:"Название, \bкак в каталоге",
    };
function ExhibitTable({sessionField, handleClickPeople}) {
    
    const [currentExhibit, setCurrentExhibit] = useState(null)
    const {exhibits} = sessionField
    const sortedExhibits = [...exhibits].sort((a,b)=> a.number_in_catalog - b.number_in_catalog)
    // console.log(exhibits)
  return (
  <>
    <TableContainer sx={{ maxHeight: "100%" }}>
    <Table>
        <TableHead>
            <TableRow sx={{
                bgcolor:"rgba(135, 2, 3, 0.1)"
            }}>
                {
                    Object.keys(varList).map((element,index)=>{
                        return (
                            <TableCell
                                key={index}
                                // id={headCell.id}
                                align={"center"}
                                // sortDirection={orderBy === headCell.id ? order : false}
                            >
                                {varList[element]}
                            </TableCell>
                        )
                    })
                }
            </TableRow>
        </TableHead>
        <TableBody>
                {
                    exhibits && sortedExhibits && sortedExhibits.map((exhibit,exhiIndex) => {
                        const {number_in_catalog, author_in_catalog, showpiece_in_catalog} = exhibit
                        const handleClickExhibit = (event)=>{
                            setCurrentExhibit(exhibits[event.target.id])
                        }
                        const hasAuthor = author_in_catalog && `${author_in_catalog}`.toLowerCase() !== "автор не указан" && exhibit.showpiece?.author?.person_id
                        return <TableRow key={exhiIndex}>
                        <TableCell
                            align={"center"}
                        >
                            {number_in_catalog}
                        </TableCell>
                        <TableCell
                            align={"left"}
                        >
                            <Typography
                                variant={hasAuthor?"person_link":""}
                                id={exhibit?.showpiece?.author?.person_id}
                                className={hasAuthor?"one-people":""}
                                fontSize="1.2rem"
                                onClick={hasAuthor?handleClickPeople:undefined}
                            >

                            {author_in_catalog}
                            </Typography>
                        </TableCell>
                        <TableCell
                            align={"left"}
                        >
                        <Typography
                            id={exhiIndex}
                            variant={'person_link'}
                            fontSize="1.2rem"
                            onClick={handleClickExhibit}
                            sx={{
                                cursor:'pointer',
                                "&:hover":{
                                    textDecoration: 'underline',
                                }
                            }}
                        >

                            {showpiece_in_catalog}
                            </Typography>
                        </TableCell>
                        </TableRow>
                    })
                }
        </TableBody>
    </Table>
    </TableContainer>
    {
        currentExhibit && <ExhibitModal exhibit={currentExhibit} onClose={()=>setCurrentExhibit(null)}/>
    }
  </>
  )
}

export default ExhibitTable