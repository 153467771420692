import { Box, Typography } from '@mui/material'
import React from 'react'

function EmptyResults() {
  return (
    <Box
    sx={{
        width:'100%',
        height:'400px',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    }}
    >
        <Typography
            variant='h5'        
        >
            К сожалению, по Вашему запросу не было найдено ни одного совпадения.
        <Typography
            variant='body2' 
            pt={2}  
            align='center'    
        >
            Попробуйте изменить условия поиска
        </Typography>
        </Typography>
    </Box>
  )
}

export default EmptyResults