import { Box, Slider } from "@mui/material";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const marks = [
  {
    value: 1850,
  },
  {
    value: 1917,
  },
];

function DateSlider() {
  let [searchParams, setSearchParams] = useSearchParams();
  const search = {};
  for (let entry of searchParams.entries()) {

    if (entry[0] === "exhYearStart" || entry[0] === "exhYearFinish") {

      search[entry[0]] = entry[1];
    }
  }

  const [value1, setValue1] = useState([
    +search.exhYearStart || 1850,
    +search.exhYearFinish || 1917,
  ]);

  function handleChange(event, newValue, activeThumb) {
    const is_same =
      value1.length == newValue.length &&
      value1.every(function (element, index) {
        return element === newValue[index];
      });
    if (!is_same) setValue1(newValue);
  }

  function setParams(val) {
    const newParams = [
      +searchParams.get("exhYearStart"),
      +searchParams.get("exhYearFinish"),
    ];
    const is_same =
      val.length == newParams.length &&
      val.every(function (element, index) {
        return element === newParams[index];
      });
    if (!is_same) {
      const search2 = {};
      for (let entry of searchParams.entries()) {
        search2[entry[0]] = entry[1];
      }
      //Устанавливаем новые парамс для даты
      setSearchParams({
        ...search2,
        exhYearStart: val[0],
        exhYearFinish: val[1],
      });
    }
  }
   const verifySetParams = useCallback(
    debounce((val)=>setParams(val), 200),
    []
  );
  useEffect(() => {
    verifySetParams(value1)
  }, [value1]);

  return (
    <Box
      sx={{
        minWidth: "300px",
        maxWidth: "600px",
        pt: 4,
        pl: 2,
        pr: 2,
      }}
    >
      <Slider
        marks={marks}
        getAriaLabel={() => "Temperature range"}
        value={value1}
        onChange={handleChange}
        defaultValue={value1}
        min={1850}
        max={1917}
        valueLabelDisplay="on"
        disableSwap
        sx={{
          color: "rgba(135,2,3,1)",
          "& .MuiSlider-thumb": {
            borderRadius: "2px",
          },
          "& .MuiSlider-valueLabel": {
            fontSize: "18px",
            backgroundColor: "rgba(135,2,3,0)",
            border:'1px solid rgba(135,2,3,0.5)',
            color: "rgba(0,0,0,0.6)",
            // color: "white",
          },
        }}
      />
    </Box>
  );
}

export default DateSlider;
