import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import OneExhDescription from "../Components/OneExhContent/OneExhDescription";
import OneExhSessionTable from "../Components/OneExhContent/OneExhSessionTable";

function OneExhibitionPage() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = params;

  const [oneExh, setOneExh] = useState(null);

  useEffect(() => {
    if (id !== 0) sendRequestAPI(id);
  }, [id]);

  const handleOpenOneSession = (session) => {
    navigate(`${location.pathname}/session/${session?.session_id}`, { state: { session:{...session,exhibition:oneExh.title} } })
  };

  const handleMobileOpenOneSession = (id) => {
    handleOpenOneSession(oneExh.sessions[id]);
  };

  const sendRequestAPI = async (id) => {
    const request = await fetch(`/api/v1/exhibition/${id}`)
      .then((response) => response.json())
      .finally();
    setOneExh({ ...request });
  };
  
  if (!oneExh || oneExh?.detail || oneExh.title.indexOf("Тест: ") === 0)
    return (
      <Box
        height={200}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography variant="person_label">{`${
          oneExh?.detail || "Страница не найдена, или недоступна"
        }`}</Typography>
      </Box>
    );
  return (
    <Box
      key="exh-onewrapper"
      sx={{
        backgroundColor: "white",
        minWidth: "100vw",
        maxWidth: "100vw",
        minHeight: {
          xs: "calc( 100vh - 60px )",
          sm: "calc( 100vh - 105px )",
          md: "calc( 100vh - 150px )",
        },
      }}
    >
      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          gap: 4,
          minHeight: "100%",
          maxHeight: "100%",
          p: "20px",
        }}
      >
        <Grid item xs={12}>
          <OneExhDescription oneExh={oneExh} />
        </Grid>
        <Grid item xs={12}>
          <OneExhSessionTable
            oneExh={oneExh}
            handleOpenOneSession={handleOpenOneSession}
            handleMobileOpenOneSession={handleMobileOpenOneSession}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default OneExhibitionPage;
