import { Popover } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import PersonContent from "./PersonContent";

function PersonPopper({
  id,
  open,
  anchorEl,
  handleClose,
  currentPerson,
  currentExhTitle,
}) {
  const navigate = useNavigate();

  if (currentPerson) {
    const handleMoveToExh = (event) => {
      const oneExh = currentPerson.exhibits.find(
        (exh) => exh.exhibition_id == event.currentTarget.id
      );
      navigate(`/one-exhibit/${event.currentTarget.id}`, {
        replace: false,
        state: { oneExh: oneExh },
      });
    };

    const hasYear =
      currentPerson.birth_year.length > 0 ||
      currentPerson.death_year.length > 0;
    const livePeriod = () => {
      if (currentPerson.birth_year && !currentPerson.death_year) {
        return "года рождения";
      }
      if (!currentPerson.birth_year && currentPerson.death_year) {
        return "год смерти";
      }
      return false;
    };
    const hasLivePeriod = livePeriod();
    return (
      <Popover
        id={id}
        open={open}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          zIndex: 12000,
          "&>.MuiPopover-paper": {
            minWidth: "600px",
            maxWidth: "50vw",
            minHeight: "600px",
            padding: "10px 20px",
          },
        }}
      >
        <PersonContent
          currentPerson={currentPerson}
          hasYear={hasYear}
          hasLivePeriod={hasLivePeriod}
          handleMoveToExh={handleMoveToExh}
          currentExhTitle={currentExhTitle}
        />
      </Popover>
    );
  } else {
    return <></>;
  }
}

export default PersonPopper;
