import {
  Box,
  CardMedia,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import ExhibitTable from "../Components/ResultTable/ExhibitTable";

function createData(
  title,
  start_year,
  finish_year,
  old_style_start_year,
  old_style_finish_year,
  city_list,
  members,
  exhibition_id,
  organizations,
  exhType,
  exhKind,
  sessionPlace,
  sessionAddress
) {
  return {
    title,
    'start_year': old_style_start_year !== '-' && old_style_start_year + '/' + start_year,
    'finish_year': finish_year + "/" + old_style_finish_year,
    old_style_start_year,
    old_style_finish_year,
    city_list,
    members,
    exhibition_id,
    organizations,
    exhType,
    exhKind,
    sessionPlace,
    sessionAddress,
  };
}

function createList(sessions, variableName, subVarName = false) {
  let arr = [];
  if (sessions.length === 0) arr.push("нет данных");
  else
    arr = sessions.map((element) => {
      if (variableName === "places" && element[variableName].length > 0) {
        return element[variableName][0][subVarName];
      } else {
        return element[variableName];
      }
    });
  return arr;
}
function createMembersCounter(sessions = []) {
  let members = 0;
  if (sessions.length === 0) return members;
  else
    sessions.forEach((element) => {
      members += element.participants.length;
    });
  return members;
}

export function resultDataSet(exhList = []) {
  let resultData = [];
  if (exhList.length === 0) return [];
  else {
    exhList.forEach((exh) => {
      resultData.push(
        createData(
          exh.title,
          exh.exhYearStart || "-",
          exh.exhYearFinish || "-",
          exh.exhYearOldStyleStart || "-",
          exh.exhYearOldStyleFinish || "-",
          createList(exh.sessions, "city"),
          createMembersCounter(exh.sessions),
          exh.exhibition_id,
          exh.organizations,
          exh.exhType,
          exh.exhKind,
          createList(exh.sessions, "places", "title") || "-",
          createList(exh.sessions, "places", "address") || "-"
        )
      );
    });
  }
  return resultData;
}
export function checkSessionField(
  sessionField,
  element,
  city,
  handleClickParticipant = () => {},
  handleClickPeople = () => {},
  handleImage = () => {},
  offset = 0
) {
  const handleImageClick = (event) => {
    const { id } = event.target;
    if (id.split("_")[0] === "exhibit")
      handleImage(
        "/" +
          sessionField[id.split("_")[1]].exhibits[id.split("_")[2]]?.showpiece
            .image
      );
    else
      handleImage(
        sessionField[id]?.file.length > 0
          ? sessionField[id].file
          : sessionField[id].image
      );
  };

  if (sessionField && Object.keys(sessionField).length > 0) {
    let arr = [];
    if (element === "participants") {
      sessionField.map((people, index) => {
        arr.push(
          <Typography
            variant="person_link"
            id={people.person_id}
            className="one-author"
            key={"participants" + people.person_id + index}
            onClick={handleClickParticipant}
          >
            {index + 1 + offset}. {people.fio}
          </Typography>
        );
      });
      return arr;
    }
    if (element === "people") {
      sessionField.map((people, index) => {
        arr.push(
          <Typography
            variant="person_link"
            id={people.person_id}
            className="one-people"
            key={"people" + people.person_id + index}
            onClick={handleClickPeople}
          >
            {index + 1 + offset}.{" "}
            {people.pseudonym ? people.pseudonym + " (" : ""}
            {people.fio}
            {people.pseudonym ? ")" : ""}
          </Typography>
        );
      });
      return arr;
    }
    if (element === "places") {
      sessionField.map((place, index) => {
        arr.push(
          <Typography
            variant="exh_text"
            id={place.place_id}
            className="one-place"
            key={"place" + place.person_id}
          >
            {index > 1 && index + 1 + "."}
            {place.title ? `${place.title}. ` : ""}{" "}
            {place.address.length > 0 &&
              `По адресу: г. ${city}, ${place.address}`}
          </Typography>
        );
      });
      return arr;
    }
    if (element === "editions") {
      sessionField.map((edition, index) => {
        arr.push(
          edition.url ? (
            <Stack direction={"row"} gap={2} key={"edition link" + index}>
              <Link
                href={edition.url}
                target="_blank"
              >
                <Typography
                  variant="exh_text"
                  id={"edition-" + index}
                  className="one-edition"
                >
                  {index + 1}. {edition?.title}{" "}
                  {edition?.type ? `(${edition.type})`.toLowerCase() : ""}
                </Typography>
              </Link>
              {edition?.file?.length > 0 && (
                <Link
                  href={edition.file}
                  key={"edition link" + index}
                  target="_blank"
                >
                  <Typography
                    variant="exh_text"
                    id={"edition-" + index}
                    className="one-edition"
                  >
                    скачать
                  </Typography>
                </Link>
              )}
            </Stack>
          ) : (
            <Stack direction={"row"} gap={2}>
              <Typography
                variant="exh_text"
                id={"edition-" + index}
                key={"edition" + index}
                className="one-edition"
              >
                {index + 1}. {edition?.title}{" "}
                {edition?.type ? `(${edition.type})`.toLowerCase() : ""}
              </Typography>
              {edition?.file?.length > 0 && (
                <Link
                  href={edition.file}
                  key={"edition link" + index}
                  target="_blank"
                >
                  <Typography
                    variant="exh_text"
                    id={"edition-" + index}
                    className="one-edition"
                  >
                    скачать
                  </Typography>
                </Link>
              )}
            </Stack>
          )
        );
      });
      return arr;
    }
    if (element === "images") {
      sessionField.map((image, index) => {
        const imageSrc = image.file || image.image || "";
        arr.push(
          <Tooltip title="Увеличить изображение" key={"tooltip-image" + index}>
            <CardMedia
              onClick={handleImageClick}
              id={index}
              key={"image" + index}
              className="one-image"
              component="img"
              sx={{
                width: "240px",
                cursor: "pointer",
                objectFit: "contain",
              }}
              image={imageSrc}
              alt={"image" + index}
            />
          </Tooltip>
        );
      });
      return (
        <Box
          sx={{
            display: "flex",
            gap: 3,
            flexWrap: "wrap",
          }}
        >
          {arr}
        </Box>
      );
    }
    if (element === "structures") {
      arr.push(<ExhibitTable key={"structures-"+0} sessionField={sessionField[0]} handleClickPeople={handleClickPeople}/>) 
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            width: "95%",
            // flexWrap: "wrap",
          }}
        >
          {arr}
        </Box>
      );
    }
  } else {
    return <>-</>;
  }
}
export function genChipsListSpecial(currentFilters) {
  let arr = [];
  for (const key in currentFilters) {
    const element = currentFilters[key];
    if (`${element}` === "true" || `${element}` === "false") {
      arr.push({ text: element, label: key, isNegative: false, id: 0 });
    } else {
      if (typeof element === "string") {
        if (element.length > 0)
          arr.push({ text: element, label: key, isNegative: false, id: 0 });
      } else {
        if (element.length > 0) {
          element.map((field, index) => {
            if (field.text && field.text.length > 0) {
              arr.push({
                text: field.text,
                label: key,
                isNegative: field.isNegative,
                id: index,
              });
            } else {
              Object.keys(field).map((keyField, ind) => {
                arr.push({
                  text: field[keyField],
                  label: keyField,
                  isNegative: false,
                  id: index,
                });
              });
            }
          });
        }
      }
    }
  }
  return arr.length === 0 ? null : arr;
}

export function genParamsList(currentFilters) {
  let arr = {};
  for (const key in currentFilters) {
    const element = currentFilters[key];
    arr[element.label + (element.id + 1)] =
      (element.isNegative ? "!" : "") + element.text;
  }
  return arr;
}

function checkDateByOldStyle(label, isOld) {
  if (!isOld) return label;

  String.prototype.insert = function (what, index) {
    return index > 0
      ? this.replace(new RegExp(".{" + index + "}"), "$&" + what)
      : what + this;
  };
  const index = () => {
    if (label.indexOf("Start") !== -1) return label.indexOf("Start");
    return label.indexOf("Finish");
  };
  return label.insert("OldStyle", index());
}

export function genRequestDataSet(arrayObj, dateIsOldStyle = false) {
  let requestDataSet = {};
  arrayObj.map((filter) => {
    if (
      filter.label === "query" ||
      filter.label === "isImg" ||
      filter.label === "isEdition"
    ) {
      requestDataSet[filter.label] = filter.text;
    } else {
      if (filter.label.indexOf("Year") > 0) {
        const filterLabel = filter.label.replace(
          /(?:Start|Finish|OldStyle)$/gu,
          ""
        );
        if (Object(requestDataSet).hasOwnProperty(filterLabel)) {
          requestDataSet[filterLabel][0] = {
            ...requestDataSet[filterLabel][0],
            [checkDateByOldStyle(filter.label, dateIsOldStyle)]: filter.text,
          };
        } else {
          requestDataSet[filterLabel] = [
            {
              [checkDateByOldStyle(filter.label, dateIsOldStyle)]: filter.text,
            },
          ];
        }
      } else {
        if (!requestDataSet[filter.label]) {
          requestDataSet[filter.label] = [];
        }
        requestDataSet[filter.label][filter.id] = {
          text: filter.text,
          isNegative: filter.isNegative,
        };
      }
    }
  });
  return requestDataSet;
}

export function getParamsInObject(searchParams) {
  let variables = {};
  for (const [key, value] of searchParams) {
    variables[key] = value;
  }
  return variables;
}
