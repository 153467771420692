import { Box } from "@mui/material";
import debounce from "lodash.debounce";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { generateResponseData } from "../../Reducers/appReducer";
import ButtonOpenAdvanced from "../Buttons/ButtonOpenAdvanced";
import QueryInputSimpleBlock from "../Forms/QueryInputSimpleBlock";
import SimpleFilterBlock from "./SimpleFilterBlock";

function SearchBlock() {
  const dispatch = useDispatch();
  const isLastRef = useRef(null);
  const requestRef = useRef(null);

  let [searchParams, setSearchParams] = useSearchParams();

  const getParams = () => {
    let arr = [];
    searchParams.forEach(function (value, key) {
      arr.push([value, key]);
    });
    return arr.length > 0 ? true : false;
  };
  const currentFilters = useSelector((state) => state.exhi.currentFilters);
  const dateIsOldStyle = useSelector((state) => state.exhi.dateIsOldStyle);

  const [searchState, setSearchState] = useState({
    query: "",
    isImg: false,
    isEdition: false,
    startYear: 1850,
    finishYear: 1917,
  });

  const abortController = useRef(null);

  const arr = ["query", "isImg", "isEdition", "exhYearStart", "exhYearFinish"];

  useEffect(() => {
    if (currentFilters) {
      let obj = {};
      arr.map((el, ind) => {
        isLastRef.current = arr.length === ind + 1;
        const one = currentFilters.find((filter) => filter.label === el);
        if (one) {
          switch (el) {
            case "query":
              obj.query = one.text;
              break;
            case "isImg":
              obj.isImg = one.text;
              break;
            case "isEdition":
              obj.isEdition = one.text;
              break;
            case "exhYearStart":
              obj.startYear = +one.text;
              break;
            case "exhYearFinish":
              obj.finishYear = +one.text;
              break;
            default:
              break;
          }

        }
      });
      setSearchState((prevState) => ({ ...obj }));
    }
  }, [currentFilters]);


  useEffect(() => {
    sendRequest(getFilters(searchState, dateIsOldStyle));
  }, [searchState, dateIsOldStyle]);

  function getFilters(data, isOldStyle) {
    const obj = {};
    if (data.query && data.query.length > 0) obj.query = data.query;
    if (data.isImg) obj.isImg = data.isImg;
    if (data.isEdition) obj.isEdition = data.isEdition;
    if (data.startYear || data.finishYear) {
      obj.exhYear = [
        {
          [isOldStyle?'exhYearOldStyleStart':'exhYearStart']: data.startYear || "",
          [isOldStyle?'exhYearOldStyleFinish':'exhYearFinish']: data.finishYear || "",
        },
      ];
      
    }
    return obj;
  }

  function sendRequest(obj){
    if (Object.keys(obj).length > 0 || !getParams()) {
      if (requestRef.current) {
        requestRef.current.abort();
        const resp = dispatch(
          generateResponseData({ obj, abortController: abortController })
        );
        requestRef.current = resp;
      } else {
        requestRef.current = dispatch(
          generateResponseData({ obj, abortController: abortController })
        );
      }
    }
  }
  sendRequest = debounce(sendRequest,500)
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "20vh",
        display: "flex",
        flexDirection: "column",
        p: {xs:"20px",sm:"20px",md:"40px"},
        pt:{xs:"60px",sm:"60px",md:"60px"},
        gap: "1rem",
      }}
    >
      <Box
        sx={{
          width: {xs:"calc(100% - 40px)",md:'80%', xl:'50%'},
          display: "flex",
          gap: "1rem",
        }}
      >
        <QueryInputSimpleBlock />
      </Box>
      <SimpleFilterBlock />
      <ButtonOpenAdvanced />
    </Box>
  );
}

export default SearchBlock;
