import { Box, InputBase } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import ButtonSimpleSearch from '../Buttons/ButtonSimpleSearch';

function QueryInputSimpleBlock() {
  let [searchParams, setSearchParams] = useSearchParams();
   const search = {}
    for (let entry of searchParams.entries()) {
      if (entry[0] === "query")
        search[entry[0]] = entry[1]
    }
  const [value, setValue] = useState(search.query || "")
  const handleChangeInput = (event) => {
    setValue(event.target.value)
  }

  useEffect(() => {
   const search2 = {}
    for (let entry of searchParams.entries()) {
      if (entry[0] !== "query")
        search2[entry[0]] = entry[1]
    }
    if (value.length>0) {
        setSearchParams({...search2, query:value})
    } else {
        setSearchParams({...search2})
    }
  }, [value])
  
  return (
    <Box
      sx={{
        display:'flex',
        gap:1,
        justifyContent:'space-between',
        width:'100%'
      }}
    >
        <InputBase
          value={value}
          onChange={handleChangeInput}
          placeholder="Искать по всем полям"
          sx={{ border: "1px solid rgba(135,2,3,0.8)",borderRadius:'4px', flexGrow: 1,fontSize:'20px',pl:{xs:0.5,md:2},backgroundColor:'white' }}
        />
        <ButtonSimpleSearch/>
    </Box>
  )
}

export default QueryInputSimpleBlock