import { Chip, Grid, Paper, Tooltip, Typography } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

const varLabelList = {
  query: "Полнотекстовый поиск",
  exhName: "По названиям выставки",
  exhYearStart: "Выставки прошли после",
  exhYearFinish: "Выставки прошли до",
  exhType: "По типу выставки",
  exhKind: "По видам искусства",
  exhOrgName: "По названиям организаций",
  isEdition: "Обязательное условие",
  isImg: "Обязательное условие",
  sessionCity: "По городам проведения",
  sessionPlace: "По местам проведения",
  sessionAddress: "По адресам проведения",
  sessionYearStart: "Выставки проведены после",
  sessionYearFinish: "Выставки проведены до",
  personF: "По фамилиям участников",
  personI: "По именам участников",
  personYearBirthStart: "По году рождения участников после",
  personYearBirthFinish: "По году рождения участников до",
  personYearDeathStart: "По году смерти участников после",
  personYearDeathFinish: "По году смерти участников до",
  personNationality: "По национальности участников",
  personCitizen: "По гражданству участников",
  personGender: "По полу участников",
  personExhRole: "По роли участников",
  personSpeciality: "По специализациям участников",
};

function ChipsBlock() {
  const currentFilters = useSelector((state) => state.exhi.currentFilters);
  let [searchParams, setSearchParams] = useSearchParams();

  const handleDelete = (label, id) => {
    const paramLabel = label + (id + 1);
    let arrSameParams = [];
    let arrOtherParams = [];
    let arrToSet = [];
    searchParams.forEach(function (value, key) {
      if (key.indexOf(label) >= 0) arrSameParams.push([key, value]);
      if (key.indexOf(label) === -1) arrOtherParams.push([key, value]);
    });
    arrToSet = arrOtherParams;
    let ind = 1;
    arrSameParams.map((param, index) => {
      if (param[0] !== paramLabel) {
        arrToSet.push([label + ind, param[1]]);
        ind += 1;
      }
    });
    setSearchParams(arrToSet);
  };
  if (!currentFilters) {
    return undefined;
  } else {
    return (
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          position: "sticky",
          top: "150px",
          borderTop: "5px solid rgba(135,2,3,1)",
        }}
      >
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "inherit",
            flexWrap: "wrap",
            gap: 0.5,
            listStyle: "none",
            p: 2,
            m: 0,
            "&.MuiPaper-root": {
              boxShadow: "none",
            },
          }}
          component="ul"
        >
          {currentFilters.map((filter, index) => {
            const icon =
              filter.label === "query" ? (
                <SearchIcon key={`${filter.label} - ${filter.id}-${index}`} />
              ) : (
                <Typography
                  variant="body2"
                  key={`${filter.label} - ${filter.id}-${index}`}
                >
                  {varLabelList[filter.label]}
                  {filter.id > 0 ? " (" + (filter.id + 1) + ")" : ""}:
                </Typography>
              );
            const generateChipLabel = () => {
              let str = `${filter.text}`;
              if (filter.label === "isEdition")
                str = filter.text == "true" ? "С публикациями" : undefined;
              if (filter.label === "isImg")
                str = filter.text == "true" ? "С изображениями" : undefined;
              if (filter.label === "personGender")
                str = filter.text === "муж" ? "Мужской" : "Женский";
              if (filter.isNegative) str = str + " (исключить)";
              return str;
            };
            if (!filter.text || filter.text == "false") return <React.Fragment key={`unview-block-${filter.id}-${index}`}></React.Fragment>;
            return (
              <Tooltip
                title={`${varLabelList[filter.label]}${
                  filter.id > 0 ? " № " + (filter.id + 1) : ""
                }`}
                key={`chip-${filter.label}- ${filter.id}-${index}`}
              >
                <Chip
                  icon={icon}
                  variant="filled"
                  label={generateChipLabel()}
                  // onDelete={() => handleDelete(filter.label + (filter.id + 1))}
                  onDelete={() => handleDelete(filter.label, filter.id)}
                />
              </Tooltip>
            );
          })}
        </Paper>
      </Grid>
    );
  }
}

export default ChipsBlock;
