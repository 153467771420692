import { Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingProgress from "../Components/Blocks/LoadingProgress";
import RawHTML from "../Components/Blocks/RawHtml";
import { fetchWebSiteContent } from "../Reducers/appReducer";

function ManualPage() {
  const content = useSelector((state) => state.exhi.content);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!content.status) dispatch(fetchWebSiteContent());
  }, [content]);

  return (
    <Container
      maxWidth="lg"
      container
      sx={{
        minHeight: "100%",
        m: "10vh auto 10vh auto",
      }}
    >
      {!content || (content.status !== "fulfilled" && <LoadingProgress />)}
      {content.data &&
        content.status === "fulfilled" &&
        content.data.map((block, index) => {
          return (
            <Grid container rowGap={4}>
              {block.search && block.search.length > 0 && (
                <>
                  <Grid item xs={12} md={5} lg={4} position={"sticky"}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: { xs: "4rem", sm: "3rem", md: "3rem" },
                      }}
                    >
                      Как искать?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={8} pt={"4rem"}>
                    <Typography variant="about_content_text">
                      <RawHTML dirtyHTML={block.search} />
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={5} lg={4} position={"sticky"}>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { xs: "4rem", sm: "3rem", md: "3rem" },
                  }}
                >
                  Глоссарий
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={8} pt={"4rem"}>
                <Typography variant="about_content_text">
                  <RawHTML dirtyHTML={block.glossary} />
                </Typography>
              </Grid>
            </Grid>
          );
        })}
    </Container>
  );
}

export default ManualPage;
