import { Paper } from "@mui/material";
import React from "react";
import SessionField from "./SessionField";

const sessionFieldVarList = [
  "city",
  "places",
  "sessionYearStart",
  "sessionYearEnd",
  "price",
  "attendance",
  "description",
  "catalog",
  "participants",
  "people",
  "editions",
  "structures",
  "images",
];
const sessionFieldLabelList = [
  "Город:",
  "Места проведения:",
  "Дата начала выставки:",
  "Дата окончания выставки:",
  "Стоимость билета на выставку",
  "Посещаемость",
  "Описание",
  "Каталог",
  "Выставляющиеся художники:",
  "Участники:",
  "Издания выставки:",
  "Экспонаты:",
  "Изображения:",
];

function updateFieldList(session) {
  let result = {

  }
  if (session?.structures && session.structures[0]) {
    result['description']=session.structures[0].description || "";

    result['catalog']=!!session?.structures[0]?.with_catalog?"есть каталог":"нет каталога"
  }
  return {...result}
}

function OneExhSessionDescription({
  currentSession,
  handleClickParticipant,
  handleClickPeople,
  handleImage,
}) {
  if (!currentSession) return <></>;
  const updatedSession = {
    ...currentSession,
    ...updateFieldList(currentSession)
  };
  return (
    <Paper
      elevation={0}
      sx={{
        p: 1,
        borderRadius: "6px",
      }}
    >
      {sessionFieldVarList.map((element, index) => {
        return (
          <SessionField 
            key={"sessionFieldVarList-" + element + index} 
            currentSession={updatedSession} 
            handleClickParticipant={handleClickParticipant} 
            handleClickPeople={handleClickPeople} 
            handleImage={handleImage} 
            element={element} 
            index={sessionFieldLabelList[index]}
          />
        );
      })}
    </Paper>
  );
}

export default OneExhSessionDescription;
