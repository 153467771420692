import { CircularProgress, Grid } from "@mui/material";
import React from "react";

function LoadingProgress() {
  return (
    <Grid container direction="column">
      <Grid
        item
        xs={12}
        sx={{
          mt: 10,
          minHeight: "100%",
          display: "flex",
          justifyContent: "center",
          "& .MuiCircularProgress-root":{
            color:'rgba(135,2,3,1)'
          }
        }}
      >
        <CircularProgress size={250} thickness={2} />
      </Grid>
    </Grid>
  );
}

export default LoadingProgress;
