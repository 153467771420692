import { Box, Typography } from "@mui/material";
import React from "react";

function NewsPage() {
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100%",
        display:'flex',
        alignItems:'center',
        flexDirection:'column',
        justifyContent:'center'
      }}
    >
      <Typography
        variant="h3"
        sx={{
          "&.MuiTypography-root": {
            // color:'blue!important'
          },
        }}
      >
        NewsPageContent
      </Typography>
      <Typography
        variant="span"
        sx={{
          "&.MuiTypography-root": {
            // color:'blue!important'
          },
        }}
      >
        Тут будут отображаться новости проекта
      </Typography>
    </Box>
  );
}

export default NewsPage;
