import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { FieldArray } from "formik";
import React from "react";
import TextfieldWrapper from "./TextfieldWrapper";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import { setDateStyle } from "../../Reducers/appReducer";

function DatePickerFieldArray({ name, label, id, values }) {
  const dateIsOldStyle = useSelector((state) => state.exhi.dateIsOldStyle);
  const dispatch = useDispatch()
  const handleChangeDateStyle = () => dispatch(setDateStyle(!dateIsOldStyle))
  const labelNameStart = `${name}Start`;
  const labelNameFinish = `${name}Finish`;
  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <Grid item container>
          {values[name].length > 0 && (
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                gap: 1,
              }}
              xs={3}
            >
              <Typography variant="advanced_caption" color="initial">
                {label}
              </Typography>
              <Button
                variant="text"
                sx={{
                  color: "rgb(135,2,3)",
                  display: values[name].length > 0 ? "block" : "none",
                  p: 0,
                  cursor: "pointer",
                  textTransform: "lowercase",
                  fontSize: "0.6rem",
                  "&:hover": { textDecoration: "underline" },
                }}
                onClick={() => push({ text: "", isNegative: false })}
              >
                Добавить ещё условие (или)
              </Button>
            </Grid>
          )}
          <Grid item key={name + "values"} xs={9} sx={{display:'flex',flexDirection:'column',gap:1}}>
            {values[name].map((el, ind) => (
              <Box
                key={name + "values" + ind}
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "nowrap",
                  justifyContent: "flex-end",
                  gap: 5,
                }}
              >
                <TextfieldWrapper
                  sx={{
                    flexGrow: 1,
                    backGroundColor: "white",
                  }}
                  type="number"
                  label={"Начало периода"}
                  name={`${name}.${ind}.${name}Start`}
                  id={id}
                />
                <TextfieldWrapper
                  sx={{
                    flexGrow: 1,
                    backGroundColor: "white",
                  }}
                  type="number"
                  label={"Конец периода"}
                  name={`${name}.${ind}.${name}Finish`}
                  id={id}
                />
                <IconButton
                  onClick={() => remove(ind)}
                  sx={{
                    // display:'none',
                    borderRadius: 0,
                    "&:hover": {
                      color: "red",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </Grid>
        </Grid>
      )}
    </FieldArray>
  );
}

export default DatePickerFieldArray;
