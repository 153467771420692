import { Box, Link, Paper, Stack, Typography } from "@mui/material";
import React from "react";

const exhFieldVarList = [
  "exhType",
  "exhKind",
  "description",
  "under_protection",
  "participation",
  "organizations",
  "in_favor_of",
  "publications",
  "sources",
  "operator",
];
const exhFieldLabelList = [
  "Тип выставки:",
  "Вид искусства",
  "Описание:",
  "Под покровительством:",
  "При участии:",
  "Институция - организаторы выставки:",
  "В пользу:",
  "Публикации выставки:",
  "Источники:",
  "Данные по выставке ввёл:",
];
function OneExhDescription({ oneExh }) {
  const validateTextOrArray = (field, elem) => {
    if (typeof field === "string") {
      if (field.includes('\n')) {
        return field.split('\n').map((e,i)=><p key={"paragraph"+i+e+Date()}>{e}<br/></p>)
      } else {
        return field;
      }
    } else {
      let resultArray = [];
      switch (elem) {
        case "publications":
          
        field.map((publication, index) => {
          resultArray.push(
            publication.url ? (
              <Link href={publication.url} key={"publication link" + index}>
                <Typography
                  variant="exh_text"
                  id={"publication-" + index}
                  className="one-publication"
                >
                  {index + 1}. {publication.title}
                  <br />
                </Typography>
              </Link>
            ) : (
              <Typography
                variant="exh_text"
                id={"publication-" + index}
                key={"publication" + index}
                className="one-publication"
              >
                {index + 1}. {publication.title}
                <br />
              </Typography>
            )
          );
        });
        return resultArray;
        case "sources": 
        field.map((source, index) => {
          resultArray.push(
            source.url ? (<Stack direction={'row'} gap={1}>
                <Typography
                  variant="exh_text"
                  id={"source-" + index}
                  className="one-source"
                  >
                  {index + 1}. {source.title}
                  <br />
                </Typography>
              <Link href={source.url} key={"source link" + index}>
                смотреть
              </Link>
              <Link href={source.file} key={"source link" + index}>
                скачать
              </Link>
                  </Stack>
            ) : (
              <Typography
                variant="exh_text"
                id={"source-" + index}
                key={"source" + index}
                className="one-source"
              >
                {index + 1}. {source.title}
                <br />
              </Typography>
            )
          );
        });
        return resultArray;
      
        default:
          return field.join(", ");
      }
      // if (elem === "publications") {}
    }
  };
  return (
    <Box id="exh-description-label" key="exh-description-label" sx={{ m: 0 }}>
      <Typography
        variant="exh_description_label"
        align="right"
        sx={{
          textAlign: "rigth",
        }}
      >
        {oneExh.title}
      </Typography>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { sm: "100%", md: "80%" },
          mr: "auto",
          mt: 5,
          p: 0.5,
          borderRadius: "6px",
          overflow: "hidden",
          backgroundColor: "inherit",
        }}
      >
        {exhFieldVarList.map((elem, index) => {
          if (!Boolean(oneExh[elem]) || String(oneExh[elem]).length === 0) {
            return (
              <React.Fragment
                key={"empty fragment" + elem + index}
              ></React.Fragment>
            );
          }
          return (
            <Box
              key={"fragment" + elem + index}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "column", md: "row" },
                width: "100%",
                gap: "8px",
                p: { xs: "8px 0", sm: "8px 0", md: "8px 0" },
              }}
            >
              <Typography
                variant="advanced_caption"
                color="initial"
                sx={{
                  pl: { xs: 0, sm: 0, md: 2 },
                  fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  maxWidth: "200px",
                  minWidth: "200px",
                }}
              >
                {exhFieldLabelList[index]}
              </Typography>
              <Typography
                variant="exh_text"
                color="initial"
                sx={{
                  pl: { xs: 0, sm: 0 },
                  pr: { xs: 1, sm: 0 },
                  whiteSpace: "pre-line",
                  fontSize: { xs: "16px", sm: "18px", md: "20px" },
                  maxWidth: { xs: "100%", sm: "100%", md: "67%" },
                  minWidth: { xs: "100%", sm: "100%", md: "67%" },
                }}
              >
                {validateTextOrArray(oneExh[elem], elem)}
              </Typography>
            </Box>
          );
        })}
      </Paper>
    </Box>
  );
}

export default OneExhDescription;
