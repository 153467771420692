import {
  Box,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonFeedbackNavMenu from "../Buttons/ButtonFeedbackNavMenu";
import ButtonHelpNavMenu from "../Buttons/ButtonHelpNavMenu";
import FeedbackDialog from "../Dialogs/FeedbackDialog";

const pages = ["Главная",  "Выставки","Как работать с сайтом", "О нас"];
const links = ["/",  "/exhibitions","/manual", "/about"];


function LinkTab({handleClose,...props}) {
  const navigate = useNavigate();

  const handleClick = (event) => {
    handleClose()
    navigate(`${links[event.target.id]}`,{replace: false})
    event.preventDefault();
  }

  return (
    <Tab
      // component="span"
      onClick={handleClick}
      sx={{
          "&.MuiButtonBase-root":{
            color:'white',
            fontWeight:300,
            letterSpacing:1,
            opacity:0.8
          // textAlign:'end',
          },
        "&.MuiTab-root":{
          fontSize:'18px',
        },
      }}
      {...props}
    />
  );
}

function NavMenu({isMobile=false, handleClose}) {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [popperOpen, setPopperOpen] = useState(false)

  const handleOpenFeedback = (event) => {
    setPopperOpen(!popperOpen)
  }
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box 
      sx={{ 
        width: "100%",
        // height: "100%",
        display: {xs:"flex"},
        // flexDirection:'column',
        // alignItems:'center',
        justifyContent:isMobile?'center':'flex-end'
    }}
    >
      <Tabs 
        value={value} 
        onChange={handleChange} 
        aria-label="nav tabs"
        orientation={isMobile?'vertical':'horizontal'}
        sx={{
        mt:{xs:"30px", sm:"0"},
        display:'flex',
          "& .MuiTabs-indicator":{
            display:'none',
          }
        }}
      >
        {pages.map((page, index) => {
          return(
          <LinkTab
            handleClose={handleClose}
            value={value} 
            index={index}
            id={index}
            key={links[index]}
            label={page}
            href={links[index]}
          />
        )})}
      </Tabs>
      <ButtonFeedbackNavMenu handleOpen={handleOpenFeedback} id="feedback"/>
      <FeedbackDialog open={popperOpen} handleClose={()=>setPopperOpen(false)}/>
    </Box>
  );
}

export default NavMenu;
