import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ResultMobileCards({ isAdvanced = false }) {
  const responseData = useSelector((state) => state.exhi.responseData);

  const navigate = useNavigate();
  const exhTotal = responseData.length;
  const handleOpenOneExh = (event) => {
    const { id } = event.currentTarget;
    let oneExh = responseData.filter((exh) => exh.exhibition_id === +id);
    navigate(`/one-exhibit/${id}`, {
      replace: false,
      state: { oneExh: { ...oneExh[0] } },
    });
  };
  return (
    <Grid container sx={{ display: { xs: "flex", sm: "none" }, gap: 2 }}>
      <Grid item xs={12}>
        {exhTotal > 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              pb: 2,
            }}
          >
            <Typography variant="capture">
              Найдено выставок - {exhTotal}
            </Typography>
          </Box>
        )}
      </Grid>
      {responseData.map((exh, index) => {
        return (
          <Grid
            key={"one-exh-grid" + exh.title + index}
            item
            container
            xs={12}
            id={exh.exhibition_id}
            onClick={handleOpenOneExh}
            sx={{
              border: "1px solid rgba(135,2,3,1)",
              backgroundColor: "rgba(255,255,255,0.9)",
              borderRadius: "4px",
              p: 1,
              display: "flex",
              gap: 1,
              flexDirection: "column",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0.2)",
              },
            }}
          >
            <Grid item>
              <Typography
                variant="mobile_card_title"
                // sx={{ lineHeight: 1.2, fontSize: "1.2rem" }}
              >
                {exh.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="mobile_card_label" sx={{ lineHeight: 1.2 }}>
                Год начала/конца:
              </Typography>
              <Typography variant="mobile_card_text" sx={{ lineHeight: 1.2 }}>
                {exh.exhYearStart}-{exh.exhYearFinish}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="mobile_card_label" sx={{ lineHeight: 1.2 }}>
                Города сессий:
              </Typography>
              <>
                {exh.sessions.filter(session=>!!session.city).map((session, sessionIndex,array) => {
                  return (
                    <Typography
                      key={"session" + exh.exhibition_id + sessionIndex}
                      variant="mobile_card_text"
                      sx={{ lineHeight: 1.2 }}
                    >
                      {" "}
                      {session.city}
                      {sessionIndex + 1 !== array.length ? "," : "."}
                    </Typography>
                  );
                })}
              </>
            </Grid>
            {isAdvanced && (
              <>
                <Grid item>
                  <Typography
                    variant="mobile_card_label"
                    sx={{ lineHeight: 1.2 }}
                  >
                    Тип выставки:
                  </Typography>
                  <Typography
                    key={"exh-type" + exh.exhibition_id}
                    variant="mobile_card_text"
                    sx={{ lineHeight: 1.2 }}
                  >
                    {" "}
                    {exh.exhType.join(", ")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="mobile_card_label"
                    sx={{ lineHeight: 1.2 }}
                  >
                    Вид искусства:
                  </Typography>
                  <Typography
                    key={"exh-kind" + exh.exhibition_id}
                    variant="mobile_card_text"
                    sx={{ lineHeight: 1.2 }}
                  >
                    {" "}
                    {exh.exhKind.join(", ")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="mobile_card_label"
                    sx={{ lineHeight: 1.2 }}
                  >
                    Институция - организаторы выставки:
                  </Typography>
                  <Typography
                    key={"exh-organizations" + exh.exhibition_id}
                    variant="mobile_card_text"
                    sx={{ lineHeight: 1.2 }}
                  >
                    {" "}
                    {exh.organizations.join(", ")}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}

export default ResultMobileCards;
