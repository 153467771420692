import { Box, CardMedia, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonStartPage from "../Buttons/ButtonStartPage";

function CarouselSection({ image }) {
  const navigate = useNavigate();
  const handleClickYear = (e) => {
    navigate(
      `/exhibitions?exhYearStart=${e.target.innerText}&exhYearFinish=${e.target.innerText}`
    );
  };
  const handleClickPlace = (e) => {
    navigate(
      `/advanced?isEdition1=false&isImg1=false&sessionCity1=${encodeURI(
        e.target.innerText
      )}`
    );
  };
  return (
    <Box
      id="wrapper-box"
      sx={{
        alignItems: "center",
        color: "black",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        flexDirection: { xs: "column", sm: "column", md: "row" },
      }}
    >
      <CardMedia
        // key={""+index+listElement}
        interval={10000}
        component="img"
        animation="slide"
        image={image.link}
        sx={{
          width: { xs: "100vw", sm: "100vw", md: "50vw" },
          maxHeight: {
            xs: "calc(100vh - 60px)",
            sm: "calc(100vh - 105px)",
            md: "calc(100vh - 150px)",
          },
          minHeight: {
            xs: "calc(100vh - 60px)",
            sm: "calc(100vh - 105px)",
            md: "calc(100vh - 150px)",
          },
          objectFit: "cover",
        }}
      />
      <Box
        sx={{
          // maxHeight: "90vh",
          // minHeight: "90vh",
          minWidth: { xs: "100%", sm: "100%", md: "50vw" },
          bottom: 0,
          left: 0,
          backgroundColor: {
            xs: "rgba(0, 0, 0, 0.636)",
            sm: "rgba(0, 0, 0, 0.636)",
            md: "rgba(0, 0, 0, 0)",
          },
          position: { xs: "absolute", sm: "absolute", md: "static" },
          color: { xs: "white", sm: "white", md: "black" },
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: { xs: 1, sm: 1, md: 3 },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            pr: { xs: "14px", sm: "14px", md: "64px" },
            pl: "14px",
            maxWidth: { xs: "80%", sm: "80%", md: "50vw" },
            fontSize: { xs: "16px", sm: "20px", md: "38px" },
            textTransform: "uppercase",
            textAlign: "end",
            wordWrap:'wrap',
            lineHeight:1,
          }}
        >
          {image.exhibition_title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            opacity: { xs: 1, sm: 1, md: 0.7 },
            fontSize: { xs: "14px", sm: "14px", md: "32px" },
            textAlign: "end",
            pr: { xs: "14px", sm: "14px", md: "64px" },
            pl: "28px",
            color: { xs: "white", sm: "white", md: "rgb(0,0,0)" },
            "&>span": {
              textDecoration: { xs: "underline", sm: "underline", md: "none" },
            },
            "&>span:hover": {
              // textDecoration:'underline',
              color: "rgb(132,3,2)",
              cursor: "pointer",
            },
          }}
        >
          {image.exhibition_year && (
            <>
              <span onClick={handleClickYear}>{image.exhibition_year}</span> г.,{" "}
            </>
          )}
          {image.exhibition_city_list.length > 0 && (
            <>
              {image.exhibition_city_list.map((city, ind) => (
                <React.Fragment key={image.exhibition_title + city + ind}>
                  {ind === 0 && (
                    <>
                      город{image.exhibition_city_list.length > 1 ? "а " : " "}
                    </>
                  )}
                  <span onClick={handleClickPlace}>{city}</span>
                  {image.exhibition_city_list.length === ind + 1 ? "" : ", "}
                </React.Fragment>
              ))}
            </>
          )}
          <br />
          {/* {text[id]} */}
        </Typography>
        <Box
          sx={{
            pr: { xs: "14px", sm: "14px", md: "64px" },
            pb: 1,
          }}
        >
          <ButtonStartPage id={image.exhibition_id} />
        </Box>
      </Box>
    </Box>
  );
}

export default CarouselSection;
