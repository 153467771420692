import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LoadingProgress from '../Components/Blocks/LoadingProgress';
import OneExhSessionDescription from '../Components/OneExhContent/OneExhSessionDescription';
import PersonModal from '../Components/Popovers/PersonModal';
import PersonPopper from '../Components/Popovers/PersonPopper';
import { clearSession, fetchSessionById } from '../Reducers/appReducer';

function SessionPage() {
    const isMobile = useMediaQuery({
      query: "(max-width: 800px)",
    });
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = params;

    const state_session = useSelector((state) => state.exhi.session.data)
    const status = useSelector((state) => state.exhi.session.status)

    const [loading, setLoading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [currentPerson, setCurrentPerson] = useState(null);
    const [session, setSession] = useState(location?.state?.session || null)
    // const session = location.state.session;
    const date = (session&& session?.sessionYearStart===session?.sessionYearEnd?session?.sessionYearStart: session?.sessionYearStart+"-"+session?.sessionYearEnd )+" года";
    // console.log("session",session);
    useEffect(() => {
        if(window.pageYOffset>0) window.scrollTo(0,0)
        return () => {
            setCurrentPerson(null);
            dispatch(clearSession())
        };
    }, [])
    useEffect(() => {
      if (id && !state_session) dispatch(fetchSessionById({id: id}))
    }, [id,state_session])
    
    
    useEffect(() => {
      setLoading(status==='pending')
    }, [status])

    useEffect(() => {
      if (state_session) setSession(state_session)
    }, [state_session])
    
    function handleNavigateToParentExhibition() {
        navigate(`/one-exhibit/${location.pathname.split("/")[2]}`)
    }
    const handleClickParticipant = async (event) => {
      setAnchorEl(event.currentTarget);
      if (event.target.id) {
        const request = await fetch(`/api/v1/person/${event.target.id}`)
          .then((response) => response.json())
          .finally(setOpen(true));
        setCurrentPerson(request);
      }
    };
    const handleClickPeople = async (event) => {
      setAnchorEl(event.currentTarget);
      if (event.target.id) {
        const request = await fetch(`/api/v1/person/${event.target.id}`)
          .then((response) => response.json())
          .finally(setOpen(true));
        setCurrentPerson(request);
      }
    };
  
    const handleClosePopper = () => {
        setAnchorEl(null);
        setOpen(false);
        setCurrentPerson(null);
      };
    
    const handleImage = (link) => {
      navigate("/images", { state: { link: link, ...location.state } });
    };
  return (
    <Box
      key="session-wrapper"
      sx={{
        backgroundColor: "white",
        minWidth: "100vw",
        maxWidth: "100vw",
        minHeight: {
          xs: "calc( 100vh - 60px )",
          sm: "calc( 100vh - 105px )",
          md: "calc( 100vh - 150px )",
        },
      }}
    >
      {
        loading ? <LoadingProgress/>:
        session && <>
        <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection:'column',
          minHeight: "100%",
          maxHeight: "100%",
          p: "20px",
        }}
      >
      <Typography variant="caption" fontSize={16} 
      onClick={handleNavigateToParentExhibition}
      sx={{
        cursor:'pointer',
        "&:hover":{
            color:'rgba(135,2,3,1)',
            textDecoration:'underline',
        }
      }}>
         {session.exhibition_title}
      </Typography>
        <Typography variant="exh_description_label">
           Выставочная сессия в городе {session.city} {date}
        </Typography>
      
        <Grid item xs={12} id="session_content">
          <OneExhSessionDescription
            currentSession={session}
            handleClickParticipant={handleClickParticipant}
            handleClickPeople={handleClickPeople}
            handleImage={handleImage}
            />
        </Grid>
        </Grid>
      {isMobile ? (
        <PersonModal
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClosePopper}
          currentPerson={currentPerson}
          currentExhTitle={session.exhibition}
        />
      ) : (
        <PersonPopper
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClosePopper}
          currentPerson={currentPerson}
          currentExhTitle={session.exhibition}
        />
      )}
        </> 
      }
      </Box>
  )
}

export default SessionPage