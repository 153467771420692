import React from "react";
import { FieldArray } from "formik";
import TextfieldWrapper from "./TextfieldWrapper";
import CheckBoxWrapper from "./CheckBoxWrapper";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";

function FieldArrayWrapper({ id, name, label, values }) {
  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <Grid item container
          sx={{
            display:'flex',
            flexDirection: {xs:'column',sm:'column',md:"row"},
          }}
        >
          {values[name].length > 0 && (
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: {xs:'row',sm:'row',md:"column"},
                width: "100%",
                alignItems: {xs:"center",sm:"center",md:"flex-start"},
                gap: 0,
                "& >*":{
                  minWidth: {xs:'150px',sm:'180px',md:"auto"},
                }
              }}
              xs={3}
            >
              <Typography variant="advanced_caption" color="initial" >
                {label}
              </Typography>
            <Button
              variant="text"
              sx={{
                color:'rgb(135,2,3)',
                display: values[name].length > 0 ? "block" : "none",
                p: 0,
                cursor: "pointer",
                textTransform: "lowercase",
                fontSize: "0.6rem",
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={() => push({ text: "", isNegative: false })}
            >
              Добавить ещё условие (или)
            </Button>
            </Grid>
          )}

          <Grid
            item
            key={name + "values"}
            xs={9}
            sx={{ minWidth:{xs: "100%", sm: "100%",md:'80%',lg:'50%'},display: "flex", flexDirection: "column", gap: 2 }}
          >
            {values[name].map((el, ind) => (
              <Box
                key={name + "values" + ind}
                sx={{
                  minWidth: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: {xs:"wrap",sm:"nowrap"},
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    width: {xs:'100%',sm:"auto"},
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  {ind + 1 !== values[name].length && (
                    <Typography
                      variant="caption"
                      color="initial"
                      sx={{ position: "absolute", bottom: -20, left: "10px" }}
                    >
                      или
                    </Typography>
                  )}

                  <TextfieldWrapper
                    sx={{
                      flexGrow: 1,
                        backgroundColor:'white',
                    }}
                    name={`${name}.${ind}.text`}
                    id={id}
                  />
                </Box>
                <CheckBoxWrapper
                  name={`${name}.${ind}.isNegative`}
                  label={"исключить"}
                  id={id}
                />
                <IconButton
                  onClick={() => remove(ind)}
                  sx={{
                    // display:'none',
                    borderRadius: 0,
                    "&:hover": {
                      color: "red",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </Grid>
        </Grid>
      )}
    </FieldArray>
  );
}

export default FieldArrayWrapper;
