import { Box, Dialog, IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import PersonContent from "./PersonContent";
import CloseIcon from '@mui/icons-material/Close';

function PersonModal({
  id,
  open,
  anchorEl,
  handleClose,
  currentPerson,
  currentExhTitle,
}) {
  const navigate = useNavigate();

  if (currentPerson) {
 const handleMoveToExh = (event) => {
    const oneExh = currentPerson.exhibits.find(
      (exh) => exh.exhibition_id == event.currentTarget.id
    );
    navigate(`/one-exhibit/${event.currentTarget.id}`, {
      replace: false,
      state: { oneExh: oneExh },
    });
  };
  const hasYear =
    currentPerson.birth_year.length > 0 || currentPerson.death_year.length > 0;
  const livePeriod = () => {
    if (currentPerson.birth_year && !currentPerson.death_year) {
      return "года рождения";
    }
    if (!currentPerson.birth_year && currentPerson.death_year) {
      return "год смерти";
    }
    return false;
  };

  const hasLivePeriod = livePeriod();
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      sx={{zIndex:13000}}
      // TransitionComponent={Transition}
    >
      <Box sx={{
        mt:{xs:'80px', sm:'105px'},
        pl:{xs:'20px', sm:'40px'},
        
      }}>
         <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 58,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <PersonContent
          currentPerson={currentPerson}
          hasYear={hasYear}
          hasLivePeriod={hasLivePeriod}
          handleMoveToExh={handleMoveToExh}
          currentExhTitle={currentExhTitle}
        />
      </Box>
    </Dialog>
  );
  } else {
    return <></>;
  }
 
}

export default PersonModal;
