export const EXH_TYPE_LIST = [
    "Ретроспективная",
	"Архитектурная",
	"Выставка художественного общества",
	"Фотографическая",
	"Археологическая",
	"Историческая",
	"Литературная",
	"Коммеморативная",
	"Международная",
	"Аукционная",
	"Выставка-продажа",
	"Благотворительная",
	"Выставки искусства старых мастеров",
	"Выставка одной картины",
	"Посмертная",
	"Ученическая",
	"Персональная",
	"Групповая",
]