import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import PersonResultMobileCards from "../ResultTable/PersonResultMobileCards";

function OneExhSessionTable({ oneExh, handleOpenOneSession, handleMobileOpenOneSession }) {
  oneExh.sessions = oneExh?.sessions.filter(session=>!!session.city)
  const handleOpen = (e) => {
    handleOpenOneSession(oneExh.sessions[e.currentTarget.id]);
  };
  return (
    <Box
      id="exh-sessions-list"
      sx={{
        m: 0,
      }}
    >
      {oneExh.sessions && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pb: 2,
          }}
        >
          <Typography variant="capture">
            Выставочные сессии ({" " + oneExh.sessions.length + " "})
          </Typography>
        </Box>
      )}
      {oneExh.sessions && (
        <Table
          sx={{
            minWidth: 650,
            display: { xs: "none", sm: "none", md: "table" },
          }}
          size="small"
          stickyHeader
          aria-label="exhi-sessions-list-table"
        >
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-root": {
                  backgroundColor: "rgba(192,2,1, 0.1)",
                  minHeight: "60px",
                  fontSize: { xs: "14px", sm: "14px", md: "16px" },
                  fontWeight: 400,
                },
              }}
            >
              <TableCell align="center">№</TableCell>
              <TableCell align="left">Город проведения</TableCell>
              <TableCell align="center" >Год начала<br/> (совр./юл.)</TableCell>
              <TableCell align="center" >Год окончания <br/>(совр./юл.)</TableCell>
              <TableCell align="center">Издания</TableCell>
              <TableCell align="center">Изображения</TableCell>
              <TableCell align="center">Участники</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {oneExh.sessions.map((session, index) => {
              return (
                <TableRow
                  onClick={handleOpen}
                  id={index}
                  hover={true}
                  key={"oneExh" + index}
                  sx={{
                    backgroundColor: "rgba(255,255,255,1)",
                    padding: "16px 0",
                    "&:last-child td, &:last-child th": { border: 0 },
                    "& *": { cursor: "pointer" },
                    "& .MuiTableCell-root": {
                      fontSize: "20px",
                      color: "rgba(0,0,0,0.6)",
                    },
                  }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="left">
                    {session.city}{" "}
                    {session.city_synonym && `(${session.city_synonym})`}
                  </TableCell>
                  <TableCell align="center">
                    {session.sessionYearStart || "-"}/{session.sessionYearOldStyleStart || "-"}
                  </TableCell>
                  <TableCell align="center">
                    {session.sessionYearFinish || "-"}/{session.sessionYearOldStyleFinish || "-"}
                  </TableCell>
                  <TableCell align="center">
                    {session.editions.length > 0
                      ? session.editions.length
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {session.images.length > 0 ? session.images.length : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {session.participants.length + session.people.length}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      {oneExh && (
        <Grid
          container
          item
          sx={{ display: { xs: "flex", sm: "flex", md: "none" }, gap: 1 }}
        >
          {oneExh.sessions.map((session, index) => {
            return (
              <PersonResultMobileCards
                session={session}
                index={index}
                key={"person-mobile-card" + session.session_id + index}
                handleMobileOpenOneSession={handleMobileOpenOneSession}
              />
            ); 
          })}
        </Grid>
      )}
    </Box>
  );
}

export default OneExhSessionTable;
