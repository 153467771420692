import { Box } from "@mui/material";
import React, { useEffect } from "react";
import SearchBlock from "../Components/Blocks/SearchBlock";
import ResultTable from "../Components/ResultTable/ResultTable";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setCurrentFilters } from "../Reducers/appReducer";
import EmptyResults from "../Components/Blocks/EmptyResults";
import ResultMobileCards from "../Components/ResultTable/ResultMobileCards";
import { getParamsInObject } from "../Utils/ExhibitionsUtils";

function ExhibitionsPage() {
  const dispatch = useDispatch();
  const showResults = useSelector((state) => state.exhi.showResults);
  const currentFilters = useSelector((state) => state.exhi.currentFilters);
  const noResults = useSelector((state) => state.exhi.noResults);

  let [searchParams, setSearchParams] = useSearchParams();

  function setFilters() {
    let newFilters = [];
    searchParams.forEach(function (value, key) {
      const newFilter = {
        text: value === "true" ? true : value,
        label: `${key}`,
        isNegative: false,
        id: newFilters.length,
      };
      if (value) {
        if (currentFilters) {
          let oneFilter = currentFilters.find((filter) => filter.label === key);
          if (oneFilter) {
            if (oneFilter.text === value) {
              newFilters.push(oneFilter);
            } else {
              newFilters.push(newFilter);
            }
          } else {
            newFilters.push(newFilter);
          }
        } else {
          newFilters.push(newFilter);
        }
      }
    });
  dispatch(setCurrentFilters(newFilters))
  }
  useEffect(() => {
    setFilters();
  }, [searchParams]);

  return (
    <Box
      id="ExhibitionPageWrapper"
      sx={{
        width: "100%",
        minHeight: "100vh",
        overflow: "hidden",
        backgroundColor: "rgba(255,255,255, 1)",
      }}
    >
      <SearchBlock />
      <Box
        sx={{
          minHeight: "20vh",
          backgroundColor: "rgba(255,255,255, 1)",
          p: { xs: "8px", sm: "10px", md: "40px" },
        }}
      >
        {showResults && <ResultTable />}
        {showResults && <ResultMobileCards/>}
        {noResults && <EmptyResults />}
      </Box>
    </Box>
  );
}

export default ExhibitionsPage;
