import { Box, Drawer } from "@mui/material";
import React from "react";
import { useState } from "react";
import AccordionFormItemsList from "../Forms/AccordionFormItemsList";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
function DrawerAdvancedToolsList({values}) {
    const [open, setOpen] = useState(true)
    const handleClose = () => {
        setOpen(false)
    }
  return (
    <Drawer
      sx={{
        "&>.MuiPaper-root": {
          backgroundColor: "white",
          borderRight: "5px solid rgb(135, 2, 3)",
        },
      }}
       variant={open?"temporary":"permanent"} 
      anchor="left"
      open={open}
      onClose={handleClose}
      onClick={!open?()=>setOpen(true):undefined}
    >
      <Box
        sx={{
          backgroundColor: "rgba(195, 137, 89, 0.3)",
          minWidth: open?"300px":"30px",
          minHeight: "calc(100vh - 105px)",
          pt:'105px'
        }}
      >
        {
            open ? (
                <AccordionFormItemsList values={values} isDrawer={true} handleClose={handleClose}/>
            ) : (
                <Box sx={{"& .MuiSvgIcon-root":{color:'rgb(135, 2, 3)', minHeight:'40px'}, writingMode:'vertical-rl'}}><FilterAltIcon /> Выбрать фильтр</Box>
            )
        }
        
      </Box>
    </Drawer>
  );
}

export default DrawerAdvancedToolsList;
