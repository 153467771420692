import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";

function ButtonBurgerMenu({handleClick}) {
  return (
    <IconButton
      color="inherit"
      edge="start"
      onClick={()=>handleClick()}
      sx={{
         display: { xs: "block", md: "none" },
         position:'absolute',
         top:10,
         right:10,
      }}
    >
      <MenuIcon color="white" />
    </IconButton>
  );
}

export default ButtonBurgerMenu;
