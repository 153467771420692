import { Box, CardMedia, Dialog, Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { replace } from "formik";

function ZoomImage({}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { link } = location.state;
  const [openDialog, setOpenDialog] = useState(true);
  const handlerClose = () => {
    navigate(-1, { replace: true });
    setOpenDialog(false);
  };
  return (
    <Dialog fullScreen open={openDialog} onClose={handlerClose} sx={{zIndex:20000}}>
      {/* <h1>
            {imageSrc}
        </h1> */}
      <Box
        onClick={handlerClose}
        sx={{
          cursor: "pointer",
          position: "absolute",
          top: 0,
          right: 0,
          width: {xs:'30px',sm:'50px',md:"100px"},
          height: {xs:'30px',sm:'50px',md:"100px"},
          borderRadius: 0,
          opacity: 0.6,
          "&:hover": {
            opacity: 0.8,
          },
        }}
      >
        <CloseIcon
          sx={{
            width: "100%",
            height: "100%",
          }}
        />
      </Box>
      
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <CardMedia
          className="one-image"
          component="img"
          image={link}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        ></CardMedia>
      </Box>
    </Dialog>
  );
}

export default ZoomImage;
