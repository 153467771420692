import { Box, Grid, IconButton, Typography, Button } from "@mui/material";

import React from "react";
import { Field } from "formik";
import CheckBoxWrapper from "../Forms/CheckBoxWrapper";
import { EXH_TYPE_LIST } from "../../Data/ExhTypeList";
import FieldArrayWrapper from "../Forms/FieldArrayWrapper";
import { TextField } from "formik-mui";
import DatePickerFieldArray from "../Forms/DatePickerFieldArray";
import DropDownFieldArray from "../Forms/DropDownFieldArray";
import SelectField from "../Forms/SelectField";

const options = {
  personGender: {
    male: "мужской",
    female: "женский",
  },
  personExhRole: {
    author: "автор",
    manager: "организатор",
  },
  exhYear: (() => {
    let obj = {};
    for (let index = 1800; index < 1917; index++) {
      obj[index] = index;
    }
    return { ...obj };
  })(),
  sessionYearStart: (() => {
    let obj = {};
    for (let index = 1800; index < 1917; index++) {
      obj[index] = index;
    }
    return { ...obj };
  })(),
  sessionYearFinish: (() => {
    let obj = {};
    for (let index = 1800; index < 1917; index++) {
      obj[index] = index;
    }
    return { ...obj };
  })(),
  personYearBirth: (() => {
    let obj = {};
    for (let index = 1700; index < 1980; index++) {
      obj[index] = index;
    }
    return { ...obj };
  })(),
  personYearDeath: (() => {
    let obj = {};
    for (let index = 1700; index < 1980; index++) {
      obj[index] = index;
    }
    return { ...obj };
  })(),
};

const labelList = {
  query: {
    label: ["Полнотекстовый поиск"],
    variable: ["query"],
    type: ["query"],
  },
  exh: {
    label: [
      "По названиям выставок",
      "По годам",
      "По типам выставок",
      "По видам искусства",
      "По институциям",
      "Только с изданиями",
      "Только с изображениями",
    ],
    variable: [
      "exhName",
      "exhYear",
      "exhType",
      "exhKind",
      "exhOrgName",
      "isEdition",
      "isImg",
    ],
    type: ["text", "year", "select", "select", "text", "checkbox", "checkbox"],
  },
  place: {
    label: [
      "Город",
      "Место",
      "Адрес",
      // "Год начала",
      // "Год окончания"
    ],
    variable: [
      "sessionCity",
      "sessionPlace",
      "sessionAddress",
      // "sessionYearStart",
      // "sessionYearFinish",
    ],
    type: [
      "text",
      "text",
      "text",
      //  "year",
      //  "year"
    ],
  },
  member: {
    label: [
      "Фамилия",
      "Имя",
      "Год рождения",
      "Год смерти",
      "Национальность",
      "Гражданство",
      "Пол",
      "Роль в выставке",
      "Специализация",
    ],
    variable: [
      "personF",
      "personI",
      "personYearBirth",
      "personYearDeath",
      "personNationality",
      "personCitizen",
      "personGender",
      "personExhRole",
      "personSpeciality",
    ],
    type: [
      "text",
      "text",
      "year",
      "year",
      "text",
      "text",
      "dropdown",
      "text",
      "text",
    ],
  },
};
function ToolsField({ type = null, values }) {
  // type waiting for string (exh, place, member)
  return (
    <Grid container spacing={2} key={type + "-wrapper-list"} sx={{}}>
      {labelList[type].label.map((label, index) => {
        const name = labelList[type].variable[index];
        const id = labelList[type].type[index];
        if (values[name].length === 0 && id != "query")
          return <div key={"empty" + name}></div>;
        switch (id) {
          case "text":
            return (
              <Grid
                container
                key={"container" + name + id}
                item
                xs={12}
                direction="row"
                spacing={3}
                sx={{
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <FieldArrayWrapper
                  name={name}
                  label={label}
                  id={id}
                  values={values}
                />
              </Grid>
            );
          case "year":
            return (
              <Grid
                container
                item
                xs={12}
                key={"container" + name + id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <DatePickerFieldArray
                  name={name}
                  label={label}
                  id={id}
                  values={values}
                />
              </Grid>
            );
          case "dropdown":
            return (
              <Grid
                item
                container
                xs={12}
                key={"container" + name + id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <DropDownFieldArray
                  name={name}
                  label={label}
                  id={id}
                  values={values}
                  options={options[name]}
                />
              </Grid>
            );
          case "checkbox":
            return (
              <Grid item xs={6} order={-1} key={"container" + name + id}>
                <CheckBoxWrapper name={name} label={label} id={id} />
                
              </Grid>
            );
          case "query":
            return (
              <Grid
                item
                xs={12}
                key={"container" + name + id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 0,
                }}
              >
                <Typography
                  variant="body2"
                  color="initial"
                  sx={{ pt: 1.5, minWidth: "100%" }}
                >
                  {" "}
                  {label}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap:'wrap',
                    gap: 2,
                    "& input": {
                      backgroundColor: "white",
                      border: "1px solid rgba(135,2,3,0.8)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <Field
                      component={TextField}
                      name={name}
                      fullWidth={true}
                      id={id}
                      disabled={false}
                    />
                  </Box>

                  <Button type="submit" variant="contained" sx={{}}>
                    Начать поиск
                  </Button>
                </Box>
               
              </Grid>
            );
          case "select":
            return (
              <Grid 
                container
                key={"container" + name + id}
                item
                xs={12}
                direction="row"
                spacing={3}
                sx={{
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <SelectField
                  values={values}
                  name={name}
                  label={label}
                  id={id}
                />
              </Grid>
            );
          default:
            return <></>;
        }
      })}
    </Grid>
  );
}

export default ToolsField;
