import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setDateStyle } from "../../Reducers/appReducer";
import { getParamsInObject } from "../../Utils/ExhibitionsUtils";
import CheckBoxSimpleSearch from "../Forms/CheckBoxSimpleSearch";
import DateSlider from "../Sliders/DateSlider";

function SimpleFilterBlock() {
  const theme = useTheme();
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const dateIsOldStyle = useSelector((state) => state.exhi.dateIsOldStyle);
  
  useEffect(() => {
    if (dateIsOldStyle) {
      setSearchParams({
        ...getParamsInObject(searchParams),
        'old_date':true,
      })
    } else {
      setSearchParams({
        ...getParamsInObject(searchParams),
        'old_date':false,
      })
    }
  }, [dateIsOldStyle, searchParams])
  const handleChangeDateStyle = () => dispatch(setDateStyle(!dateIsOldStyle))
  return (
    <FormGroup
      sx={{
        "& .MuiTypography-root": {
          fontFamily: theme.typography.fontFamily,
        },
      }}
    >
      <Grid container>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: {xs:"row",sm:'row',md:"column"},
              flexWrap:'wrap',
              opacity:0.8,
              "& .MuiTypography-root":{
                color:'rgba(0,0,0,0.8)',
                fontSize:"18px"
              },
              "&>*":{
                minWidth:{xs:'100%'}
              }
            }}
          >
            <FormControlLabel
              control={
                <CheckBoxSimpleSearch
                  type="isImg"
                />
              }
              label="есть изображения"
            />
            <FormControlLabel
              control={
                <CheckBoxSimpleSearch
                  type="isEdition"
                />
              }
              label="есть издания"
            />
          </Box>
        </Grid>
        <Grid item xs={10} md={6}>
          <DateSlider/>
          <Box
          sx={{
            display:'flex',
            justifyContent:'flex-end'
          }}
          >
           <Typography
              sx={{
                color:'rgba(0,0,0,0.8)',
                p:"0 2rem 0 0",
                
                fontSize: "1rem",
                "& span:hover": {cursor: "pointer", textDecoration: "underline", color:'#870203' },
              }}
             
            >
              Год по {dateIsOldStyle?"старому":"новому"} стилю (<span onClick={handleChangeDateStyle}>изменить</span>)
            </Typography>
          </Box>
          
        </Grid>
      </Grid>
    </FormGroup>
  );
}

export default SimpleFilterBlock;
