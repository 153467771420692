import { Box, Grid, List, ListItemText, Typography } from "@mui/material";
import React from "react";

let varList = {
  gender:"Пол",
  specialization:"Специализация",
  nationality:"Национальность",
  birth_city: "Город рождения",
  citizens:"Гражданство",
  societies:"Входил в общества",
  work:"Место работы",
  other_information:"Биографическая информация",
  artist_or_other:"Принадлежность к художникам",
  confession:"Вероисповедание",
  where_study:"Место обучения",
};

function PersonContent({
  currentPerson,
  hasYear,
  hasLivePeriod,
  handleMoveToExh,
  currentExhTitle,
}) {
  
  return (
    <Grid container  spacing={2}>
      <Grid item xs={11} sm={12}>
        <Typography variant="person_label" sx={{lineHeight:{xs:1.2}}}>
          {`${currentPerson.surname} ${currentPerson.name} ${currentPerson.patronymic}` +
            " "}
          {hasYear && (
            <>
            <br/>
              {hasLivePeriod
                ? `(${currentPerson.birth_year}${currentPerson.death_year} ${hasLivePeriod})`
                : `(${currentPerson.birth_year} - ${currentPerson.death_year})`}
            </>
          )}
        </Typography>
      </Grid>
      {
        Object.keys(varList).filter(key=>currentPerson[key] && currentPerson[key]?.length>0).map((key,index) => {
          const field = varList[key]
          let content = currentPerson[key]
          if (key === "gender") content = (content && content==="M") ?"Мужской":"Женский"
          return <Grid item container xs={12} key={index+key} height={content.length > 0?'auto':0}>
            <Grid item xs={12} sm={4}>
            <Typography variant="person_caption" sx={{  }}>
              {field}:{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="person_text" sx={{  }}>
              {`${content}`}
            </Typography>
          </Grid>
        </Grid>
        })
      }
     
      <Grid item xs={12} sx={{ fontWeight: 800 }}>
        <Typography variant="person_caption">
          Принимал участие в выставках:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {currentPerson.exhibits.filter(el=>el.title.indexOf("Тест: ")!==0).map((exh, index) => {
            return (
              <Box
                key={index}
                id={exh.exhibition_id}
                onClick={handleMoveToExh}
              >
                <ListItemText
                  className={exh.title === currentExhTitle ? "current-exh" : ""}
                  sx={{
                    "& .MuiTypography-root:hover": {
                      cursor: "pointer",
                      textDecoration: "underline",
                    },
                    "& .MuiTypography-root span": {
                      color: "black",
                      "&:hover": {
                        textDecoration: "none",
                      },
                    },
                  }}
                >
                  <Typography variant="person_link">
                    {index + 1}. {exh.title}
                    <span>{exh.roles && ` (${exh.roles})`}</span>
                  </Typography>
                </ListItemText>
              </Box>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
}

export default PersonContent;
