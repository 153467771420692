import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import React from "react";

const CheckboxWrapper = ({ name, label, ...otherProps }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const handleChange = (evt) => {
    const { checked } = evt.target;
    setFieldValue(name, checked);
  };
  const configCheckbox = {
    ...field,
    onChange: handleChange,
  };

  const configFormControl = {};
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
  }
  const getCheckedStatus = () => {
    return !!(field.value === "true" || field.value === true);
  };
  return (
    <FormControl {...configFormControl}>
      <FormGroup>
        <FormControlLabel
          sx={{
            m: 0,
            p: 0,
            "& .MuiTypography-root": {
              fontSize: { xs: "14px", md: "20px" },
            },
            "& .MuiCheckbox-root": {
              borderRadius: 0,
            },
          }}
          control={
            <Checkbox
              checked={getCheckedStatus()}
              variant="body2"
              color="primary"
              {...configCheckbox}
            />
          }
          label={label}
        />
      </FormGroup>
    </FormControl>
  );
};
export default CheckboxWrapper;
