import { AppBar, Box, Toolbar, Typography} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import ButtonBurgerMenu from "../Buttons/ButtonBurgerMenu";
import ButtonHowToSearch from "../Buttons/ButtonHowToSearch";
import MainLogo from "../Buttons/MainLogo";
import NavMenu from "./NavMenu";

function Topbar() {
  const [open, setOpen] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 900px)",
  });
useEffect(() => {
  setOpen(false)
}, [])
useEffect(() => {
   setOpen(false)
}, [isDesktopOrLaptop])

  const handleOpenMobileMenu = () => {
    setOpen(!open);
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        // display: "flex",
        // justifyContent: "space-between",
        backgroundColor: "rgb(135,2,3)",
        zIndex: 11000,
        color: "white",
        height: {
          xs: open ? "240px" : "60px",
          sm: open ? "240px" : "105px",
          md: "150px",
        },
        pl: 1,
        pr: 1,
        "& .MuiTabs-indicator": {
          // display: "none",
        },
        "&.MuiPaper-root": {
          boxShadow: "none",
        },
      }}
    >
      <Toolbar
        disableGutters
        variant="dense"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            pr: 8,
          }}
        >
          
      <Typography
        variant="h4"
        noWrap
        component="div"
        sx={{
          display: { xs: "inline" },
          position:'absolute',
          width:{xs:'100px',sm:'120px', md: '150px' },
          top:{
            xs:!isDesktopOrLaptop?36:'8px',
            sm:!isDesktopOrLaptop?18:'8px',
          },
          left:{
            xs:!isDesktopOrLaptop?0:'18px',
            sm:!isDesktopOrLaptop?'calc(100% - 200px)':'18px',
            md:!isDesktopOrLaptop?'calc(100% - 220px)':'18px'
          },
          p:'4px 0',
          fontWeight: 200,
          fontSize:{xs:'14px', sm:'16px', md: "18px" },
          color: "hsla(0, 0%, 98.4313725490196%, 0.637)",
          // border:'1px solid hsla(0, 0%, 98.4313725490196%, 0.637)',
          borderRadius:'8px',
          textDecoration: "none",
          letterSpacing:1.5,
          bgcolor:'hsla(0, 0%, 94%, 0)',
          textAlign:!isDesktopOrLaptop?"right":'left'
        }}
      >
        Beta version
      </Typography>
          {
            isDesktopOrLaptop && <NavMenu handleClose={handleOpenMobileMenu} />
          }
          
        </Box>
        <ButtonBurgerMenu handleClick={handleOpenMobileMenu} />
        <MainLogo />
        {!isDesktopOrLaptop && (<>
        {
          open && <NavMenu isMobile={true} handleClose={handleOpenMobileMenu} />
        }
        </>)}
      </Toolbar>
    </AppBar>
  );
}

export default Topbar;
