import { Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function ButtonOpenAdvanced({ isClose = false }) {
  const navigate = useNavigate();
  const handleOpenAdvanced = () => {
    navigate(isClose ? "/exhibitions" : "/advanced");
  };
  return (
    <Button
      variant="text"
      onClick={handleOpenAdvanced}
      sx={{
        position: "absolute",
        top: { xs: 60, sm: 105, md: 150 },
        right: 10,
        zIndex: 10000,
      }}
    >
      <Typography
        variant="p"
        sx={{
          width: "auto",
          fontSize: { xs: "14px", md: "16px", xl: "18px" },
          textDecoration: "underline",
          color: "rgb(135, 2, 3)",
          "&:hover": {
            textDecoration: "none",
          },
        }}
      >
        {isClose ? "Закрыть" : "Открыть"} расширенный поиск
      </Typography>
    </Button>
  );
}

export default ButtonOpenAdvanced;
