import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CarouselSection from "./CarouselSection";

function ImageCarousel() {
  const [imageList, setImageList] = useState([]);
  useEffect(() => {
    sendRequestAPI();
  }, []);

  const sendRequestAPI = async () => {
    const request = await fetch(`/api/v1/images/`)
      .then((response) => response.json())
      .finally();
    setImageList(
      randomSort([...request]).filter((el) => el.exhibition_id !== 1)
    );
  };

  const randomSort = (list = []) => {
    let randomList = [];
    for (let i = 0; i < list.length; i++) {
      let r = Math.floor(Math.random() * (list.length - i)) + i;
      let label = list[r];
      randomList.push(list[r]);
      list[r] = list[i];
      list[i] = label;
    }
    return randomList;
  };
  return (
    <Box
      sx={{
        display: "block",
        width: "100vw",
        height: {
          xs: "calc(100vh - 60px)",
          sm: "calc(100vh - 105px)",
          md: "calc(100vh - 150px)",
        },
        overflow: "hidden",
      }}
    >
      <Carousel
        animation="fade"
        interval={10000}
        duration={1500}
        stopAutoPlayOnHover={false}
        fullHeightHover={true}
        indicators={false}
        PrevIcon={<ArrowBackIosNewIcon sx={{ fontSize: "3rem" }} />}
        NextIcon={<ArrowForwardIosIcon sx={{ fontSize: "3rem" }} />}
        navButtonsProps={{
          style: {
            backgroundColor: "rgba(0,0,0,0)",
            color: "rgb(135,2,3)",
            borderRadius: 0,
          },
        }}
      >
        {imageList.map((image, index) => {
          return <CarouselSection key={"carouselItem" + index} image={image} />;
        })}
      </Carousel>
    </Box>
  );
}

export default ImageCarousel;
