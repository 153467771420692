import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'

function SessionListDialog({open, handleClose, currentRow}) {  

  return (
      <>
    <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
    >
        <DialogTitle>
            SessionListDialog
        </DialogTitle>
        <DialogContent>
            {JSON.stringify(currentRow)}    
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions>
    </Dialog>
      
      </>
  )
}

export default SessionListDialog