import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const generateResponseData = createAsyncThunk(
  "exhibition/search",
  async (data, state) => {
    try {
      const { abortController, obj } = data;
      abortController.current = new AbortController();
      const response = await fetch(`/api/v1/exhibition/search/`, {
        signal: abortController.current.signal,
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      const res = await response.json();
      return res;
    } catch (error) {
      console.log(`Ошибка передачи запроса ${error}`);
    } finally {
      // console.log(`${23}`)
    }
  }
);

export const fetchWebSiteContent = createAsyncThunk(
  "project/content",
  async (data, state) => {
    try {
      const response = await fetch(`/api/v1/information/?page=1&page_size=100`, {
        method: "get",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      });
      const res = await response.json();
      return res;
    } catch (error) {
      console.log(`Ошибка передачи запроса ${error}`);
    } finally {
      // console.log(`${23}`)
    }
  }
);
export const fetchSessionById = createAsyncThunk(
  "exhibitions/session",
  async ({id}, state) => {
    try {
      const response = await fetch(`/api/v1/session/${id}`, {
        method: "get",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      });
      const res = await response.json();
      return res;
    } catch (error) {
      console.log(`Ошибка при поиске сессии в базе данных ${error}`);
    } finally {
      // console.log(`${23}`)
    }
  }
);
function getValidDate(isOld, payload) {
  if (!isOld) return payload
  return payload.map((el) => {
    let current = {
      ...el,
      exhYearStart: el.exhYearOldStyleStart || null,
      exhYearFinish: el.exhYearOldStyleFinish || null,
      exhMonthStart: el.exhMonthOldStyleStart || null,
      exhMonthFinish: el.exhMonthOldStyleFinish || null,
    };
    return current;
  });
}
const initialState = {
  content:{
    status:null,
    data:null
  },
  session:{
    status:null,
    data:null,
  },
  data: false,
  showResults: false,
  loading: false,
  noResults: false,
  dateIsOldStyle: false,
  formList: {
    // query:"",
    // exhYearFinish: 1917,
    // exhYearStart: 1850,
    // isEdition: false,
    // isImg: false,
  },
  currentFilters: null,
  responseData: [],
};

const exhiSlice = createSlice({
  name: "exhi",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = !state.data;
    },
    setFormList(state, action) {
      state.formList = action.payload;
    },
    setCurrentFilters(state, action) {
      state.currentFilters = action.payload;
    },
    clearResponse(state, action) {
      state.data = !state.data;
      state.showResults = false;
      state.responseData = initialState.responseData;
    },
    clearSession(state, action) {
      state.session = initialState.session;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setDateStyle(state, action) {
      if (action.payload) {
        state.dateIsOldStyle = true;
      } else {
        state.dateIsOldStyle = false;
      }
    },
  },
  extraReducers: {
    [generateResponseData.pending]: (state, action) => {
      state.loading = true;
    },
    [generateResponseData.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload) {
        if (action.payload.length === 0) {
          state.showResults = false;
          state.noResults = true;
        } else {
          state.responseData = getValidDate(
            state.dateIsOldStyle,
            action.payload
          ).filter(el=>el.title.indexOf("Тест: ")!==0);
          state.showResults = true;
          state.noResults = false;
        }
      }
    },
    [fetchWebSiteContent.pending]: (state, action) => {
      state.content.status = "pending";
    },
    [fetchWebSiteContent.fulfilled]: (state, action) => {
      state.content.status = "fulfilled";
      state.content.data = action.payload.results;
    },
    [fetchSessionById.pending]: (state, action) => {
      state.session.status = "pending";
    },
    [fetchSessionById.fulfilled]: (state, action) => {
      state.session.status = "fulfilled";
      state.session.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setData,
  clearResponse,
  clearSession,
  setCurrentFilters,
  setFormList,
  setLoading,
  setDateStyle,
} = exhiSlice.actions;

export default exhiSlice.reducer;
