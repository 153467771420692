import { Checkbox } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';

function CheckBoxSimpleSearch({type=""}) {
  let [searchParams, setSearchParams] = useSearchParams();
   const search = {}
    for (let entry of searchParams.entries()) {
      if (entry[0] ===type)
        search[entry[0]] = entry[1]
    }
  const [value, setValue] = useState(search[type]==="true")
  useEffect(() => {
    const search2 = {}
    for (let entry of searchParams.entries()) {
      if (entry[0] !==type) search2[entry[0]] = entry[1]
    }
    if (value) {
        setSearchParams({...search2,[type]:value})
    } else {
        let curObj = {}
        Object.keys(search2).map((key)=> {
            if (key!==type) curObj[key]=search2[key];
        })
        setSearchParams({...curObj})
    }
    
  }, [value])
  
const handleChange = () => {
    setValue(!value)
}
  return (
    <Checkbox
        checked={value}
        onChange={handleChange}
        variant="body2"
        size="large"
    />
  )
}

export default CheckBoxSimpleSearch