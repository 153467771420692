import { Box, Typography } from "@mui/material";
import React from "react";

function MainLogo() {
  return (
    <Box
      sx={{
        display:'flex',
        flexDirection:'column',
        width:'100%',
        minHeight:{xs:"32px",sm:"64px",md:'64px'},
        height:'100%',
        justifyContent:'center',
        gap:{xs:2,sm:2},
        pl:{xs:0,sm:2,md:4},
        pt:{xs:2,sm:2,md:0}
      }}
    >
      
    <Typography
      variant="h4"
      noWrap
      component="a"
      href="/"
      sx={{
        display: { xs: "block" },
        fontWeight: 300,
        fontSize:{xs:'18px', sm:'24px', md: "28px" },
        color: "inherit",
        textDecoration: "none",
        letterSpacing:1.5
      }}
    >
      СОБИРАТЬ И ВЫСТАВЛЯТЬ
    </Typography>
    <Typography
      variant="h6"
      noWrap
      component="span"
      sx={{
        display: {xs: "none", sm: "block", md: "block" },
        fontSize:{xs:'14px', sm:'18px'},
        fontWeight: 400,
        color: "inherit",
        textDecoration: "none",
        opacity:0.8
      }}
    >
      База данных художественных выставок и коллекций в Российской империи
    </Typography>

    </Box>
  );
}

export default MainLogo;
