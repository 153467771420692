import { Box, Grid, Typography } from "@mui/material";

import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { INITIAL_FORM_STATE } from "../../Data/FormikData";
import ToolsField from "./ToolsField";
import {
  genChipsListSpecial,
  genParamsList,
  genRequestDataSet,
} from "../../Utils/ExhibitionsUtils";
import ChipsBlock from "./ChipsBlock";
import { useSearchParams } from "react-router-dom";
import AccordionFormItemsList from "../Forms/AccordionFormItemsList";
import { useMediaQuery } from "react-responsive";
import DrawerAdvancedToolsList from "../Drawers/DrawerAdvancedToolsList";
import { setDateStyle } from "../../Reducers/appReducer";

function AdvancedToolsBlock({}) {
  let [searchParams, setSearchParams] = useSearchParams();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 900px)",
  });
  const [initialFromURL, setInitialFromURL] = useState({});
  const showResults = useSelector((state) => state.exhi.showResults);
  const currentFilters = useSelector((state) => state.exhi.currentFilters);
  const dateIsOldStyle = useSelector((state) => state.exhi.dateIsOldStyle);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentFilters) {
      setInitialFromURL(genRequestDataSet(currentFilters));
    }
  }, [currentFilters]);

  const handleSubmit = (values, { resetForm }) => {
    setSearchParams(genParamsList(genChipsListSpecial(values)));
  };
  const handleChangeDateStyle = () => {
    dispatch(setDateStyle(!dateIsOldStyle));
  };

  return (
    <Formik
      initialValues={{
        ...INITIAL_FORM_STATE,
        ...initialFromURL,
      }}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, errors, isSubmitting }) => (
        <Form>
          <Grid container>
            {isDesktopOrLaptop && (
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  borderRight: "5px solid #870203",
                  p: 2,
                }}
              >
                <AccordionFormItemsList values={values} />
              </Grid>
            )}
            {!isDesktopOrLaptop && <DrawerAdvancedToolsList values={values} />}
            <Grid
              item
              xs={12}
              md={9}
              sx={{
                p: {
                  xs: "20px 8px 20px 50px",
                  sm: "20px 8px 20px 50px",
                  md: "20px",
                },
                pt: 4,
                display: "flex",
                flexDirection: "column",
                gap: 4,
              }}
            >
              {["query", "exh", "place", "member"].map((type, index) => {
                if (type === "query") {
                  return (
                    <Box
                      key={"key-"+type+index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <ToolsField
                        type={type}
                        values={values}
                        key={"cover-tools-field-wrap" + type + index}
                      />
                      {Object.keys(values).filter(
                        (el) =>
                          String(el).indexOf("Year") !== -1 &&
                          values[el].length > 0
                      ).length > 0}
                      {Object.keys(values).filter(
                        (el) => String(el).indexOf("Year") !== -1
                      ).length > 0 && (
                        <Typography
                          variant="text"
                          sx={{
                            color: "rgba(0,0,0,0.8)",
                            fontSize: "0,8rem",
                            "& span": {
                              cursor: "pointer",
                              "&:hover": {
                                color: "rgb(135,2,3)",
                                textDecoration: "underline",
                              },
                            },
                          }}
                        >
                          Даты отображаются по{" "}
                          <b>{dateIsOldStyle ? "старому" : "новому"} стилю</b> (
                          <span onClick={handleChangeDateStyle}>изменить</span>)
                        </Typography>
                      )}
                    </Box>
                  );
                }
                return (
                  <ToolsField
                    type={type}
                    values={values}
                    key={"cover-tools-field-wrap" + type + index}
                  />
                );
              })}

              {/* {!isDesktopOrLaptop && (<Button type="text"><AddIcon/>Новый фильтр</Button>)} */}
            </Grid>
            {showResults && <ChipsBlock currentFilters={currentFilters} />}
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default AdvancedToolsBlock;
