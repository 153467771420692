import { Box, Container, Toolbar } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Topbar from "../Components/Topbar/Topbar";

const drawerWidth = "340px";
function MainWrapperPage() {
  return (
    <Box
      className="content"
      id="content-box"
      sx={{
        maxWidth: "100vw",
        minWidth: "100vw",
        minHeight: "100%",
      }}
    >
        <Topbar />
        <Box
          sx={{
            mt:{xs:"60px",sm:"105px",md:"150px"},
            minHeight: '100%',
          }}
        >
        <Outlet />
        </Box>
    </Box>
  );
}

export default MainWrapperPage;
