import { Button } from '@mui/material'
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { generateResponseData } from '../../Reducers/appReducer';

 function getFilters(data) {
    const obj = {
        exhYear:[{
            exhYearStart:1850,
            exhYearFinish:1917,
        }]
    };
    data.map((filter, index)=>{
        switch (filter.label) {
            case "query":
                obj.query=filter.text
                break;
            case "isImg":
                obj.isImg=filter.text
                break;
            case "isEdition":
                obj.isEdition=filter.text
                break;
            case "exhYearStart":
                obj.exhYear[0] = {
                    ...obj.exhYear[0],
                    exhYearStart: filter.text,
                    };
                break;
            case "exhYearFinish":
                obj.exhYear[0] = {
                    ...obj.exhYear[0],
                    exhYearFinish: filter.text,
                    };
                break;
        
            default:
                break;
        }

    })
    return obj;
  }

function ButtonSimpleSearch() {
    const dispatch = useDispatch()
    const abortController = useRef(null);

  const currentFilters = useSelector((state) => state.exhi.currentFilters);

   const handleSearch = (event) => {
    dispatch(generateResponseData({ obj:{...getFilters(currentFilters)}, abortController: abortController }));
  }
  return (
        <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSearch}
        >
          Искать
        </Button>
  )
}

export default ButtonSimpleSearch