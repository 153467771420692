import {
  Box,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import PersonModal from "./PersonModal";
import PersonPopper from "./PersonPopper";
import { useMediaQuery } from "react-responsive";

let varList = {
  comment: "комментарий",
  year: "год создания",
  kind_of_art: "вид искусства",
  genres: "жанр",
  theme: "тематика",
  technique: "техника",
  size: "размер",
  current_location: "текущее местонаходение",
  sale: "был продан",
  for_what_exhibition: "продан на выставке",
  for_whom_sold: "покупатель",
  provenance: "провенанс персоны",
  provenance_org: "провенанс институции",
};

function ExhibitModal({ exhibit, onClose }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPerson, setCurrentPerson] = useState(null);
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 800px)",
  });

  if (exhibit) {
    //   const handleMoveToExh = (event) => {
    //   const oneExh = currentPerson.exhibits.find(
    //     (exh) => exh.exhibition_id == event.currentTarget.id
    //   );
    //   navigate(`/one-exhibit/${event.currentTarget.id}`, {
    //     replace: false,
    //     state: { oneExh: oneExh },
    //   });
    // };
    const handleClickPeople = async (event) => {
      setAnchorEl(event.currentTarget);
      if (event.target.id) {
        const request = await fetch(`/api/v1/person/${event.target.id}`)
          .then((response) => response.json())
          .finally(setOpen(true));
        setCurrentPerson(request);
      }
    };
    const handleClosePopper = () => {
      setAnchorEl(null);
      setOpen(false);
      setCurrentPerson(null);
    };

    const { author_in_catalog } = exhibit
    const hasAuthor = author_in_catalog && `${author_in_catalog}`.toLowerCase() !== "автор не указан" && !!exhibit.showpiece?.author?.person_id

    return (
      <Dialog
        open={true}
        onClose={onClose}
        fullWidth={true}
        fullScreen={isMobile}
        maxWidth="xl"
        sx={{
          zIndex: 12000,
        }}
        PaperProps={{}}
      // TransitionComponent={Transition}
      >
        <Grid container pb={"80px"} sx={{ position: "relative" }}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: 6,
              }}
            >
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 24,
                  top: 24,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <Box>
                <Stack sx={{}}>
                  <Typography variant="caption">
                    название экспоната в каталоге
                  </Typography>
                  <Typography
                    variant="person_label"
                    sx={{
                      lineHeight: "100%",
                    }}
                  >
                    {exhibit.showpiece_in_catalog}
                  </Typography>
                  <Stack sx={{}}>
                    <Typography variant="caption">
                      устоявшееся название экспоната
                    </Typography>
                    <Typography
                      variant="person_label"
                      sx={{
                        lineHeight: "100%",
                      }}
                    >
                      {exhibit.showpiece.title}
                    </Typography>
                  </Stack>
                  <Stack sx={{}}>
                    <Typography variant="caption">
                      автор экспоната (как в каталоге)
                    </Typography>
                    <Typography
                      variant={hasAuthor ? "person_link" : ""}
                      id={hasAuthor ? exhibit.showpiece.author.person_id : "#"}
                      className={hasAuthor ? "one-people" : ""}
                      fontSize="1.2rem"
                      onClick={hasAuthor ? handleClickPeople : undefined}
                    >
                      {exhibit.showpiece?.author?.fio || 'автор не указан'} (
                      {exhibit.author_in_catalog})
                    </Typography>
                  </Stack>
                </Stack>
                {Object.keys(varList)
                  .filter(
                    (key) =>
                      typeof exhibit.showpiece[key] !== "string" ||
                      exhibit.showpiece[key].length > 0
                  )
                  .map((key, keyIndex) => {
                    const element = varList[key];
                    const hasData =
                      !!exhibit.showpiece[key] &&
                      exhibit.showpiece[key].length > 0 &&
                      exhibit.showpiece[key] !== null;
                    switch (typeof exhibit.showpiece[key]) {
                      case "number":
                      case "string":
                        return (
                          <Stack key={key + "_" + keyIndex + typeof exhibit.showpiece[key]}>
                            <Typography variant="caption">{element}</Typography>
                            <Typography
                              variant="person_label"
                              sx={{ lineHeight: "100%" }}
                            >
                              {exhibit.showpiece[key]}
                            </Typography>
                          </Stack>
                        );
                      case "boolean":
                        return (
                          <Stack key={key + "_" + keyIndex + typeof exhibit.showpiece[key]}>
                            <Typography variant="caption" sx={{ p: 0, m: 0 }}>
                              {element}
                            </Typography>
                            <Typography
                              variant="person_label"
                              sx={{ p: 0, m: 0, lineHeight: "100%" }}
                            >
                              {exhibit.showpiece[key] ? "да" : "нет"}
                            </Typography>
                          </Stack>
                        );
                      case "object":
                        switch (key) {
                          case "for_what_exhibition":
                            return (
                              !!exhibit.showpiece[key] && (
                                <Box key={key + "_" + keyIndex + typeof exhibit.showpiece[key]}>{JSON.stringify(exhibit.showpiece[key])}</Box>
                              )
                            );
                          case "technique":
                          case "theme":
                          case "genres":
                            return hasData ? (
                              <Stack key={key + "_" + keyIndex + typeof exhibit.showpiece[key]}>
                                <Typography variant="caption">
                                  {element}
                                </Typography>
                                <Typography
                                  variant="person_label"
                                  sx={{ lineHeight: "100%" }}
                                >
                                  {exhibit.showpiece[key]
                                    .map((e) => e.title)
                                    .join(", ")}
                                </Typography>
                              </Stack>
                            ) : (
                              <Box key={key + "_" + keyIndex + typeof exhibit.showpiece[key]}></Box>
                            );
                          case "kind_of_art":
                            return (
                              <Stack key={key + "_" + keyIndex + typeof exhibit.showpiece[key]}>
                                <Typography variant="caption">
                                  {element}
                                </Typography>
                                <Typography
                                  variant="person_label"
                                  sx={{ lineHeight: "100%" }}
                                >
                                  {exhibit.showpiece[key].title}
                                </Typography>
                              </Stack>
                            );
                          case "provenance_org":
                            return exhibit.showpiece[key].length > 0 ? (
                              <Stack key={key + "_" + keyIndex + typeof exhibit.showpiece[key]}>
                                <Typography variant="caption">
                                  {element}
                                </Typography>
                                <Stack spacing={1} key={key + "_" + keyIndex * 2}>
                                  {exhibit.showpiece[key].map((el, elIndex) => {
                                    return (
                                      <Typography
                                        variant="person_label"
                                        sx={{ lineHeight: "100%" }}
                                      >
                                        {el.title}
                                      </Typography>
                                    );
                                  })}
                                </Stack>
                              </Stack>
                            ) : (
                              <Box key={key + "_" + keyIndex + typeof exhibit.showpiece[key]}></Box>
                            );
                          case "provenance":
                            return exhibit.showpiece[key].length > 0 ? (
                              <Stack key={key + "_" + keyIndex + typeof exhibit.showpiece[key]} >
                                <Typography variant="caption">
                                  {element}
                                </Typography>
                                <Stack spacing={1} key={key + "_" + keyIndex * 2}>
                                  {exhibit.showpiece[key].map(
                                    (person, personIndex) => {
                                      return (
                                        <Typography
                                          // variant="person_link"
                                          className="one-people"
                                          id={person.person_id}
                                          variant="person_label"
                                          sx={{ lineHeight: "100%" }}
                                          onClick={handleClickPeople}
                                        >
                                          {person.fio}
                                        </Typography>
                                      );
                                    }
                                  )}
                                </Stack>
                                <Typography
                                  variant="person_label"
                                  sx={{ lineHeight: "100%" }}
                                >
                                  {exhibit.showpiece[key].title}
                                </Typography>
                              </Stack>
                            ) : (
                              <Box key={key + "_" + keyIndex + typeof exhibit.showpiece[key]}></Box>
                            );

                          default:
                            return <Box key={key + "_" + keyIndex + typeof exhibit.showpiece[key]}>-</Box>;
                        }
                      default:
                        return <Box key={key + "_" + keyIndex + typeof exhibit.showpiece[key]}>-</Box>;
                    }
                  })}
              </Box>
              {exhibit.showpiece.file && (
                <Stack>
                  <Typography variant="caption">файл</Typography>
                  <Link
                    href={"/" + exhibit.showpiece["file"]}
                    key={"exhibit link" + exhibit.id}
                    target="_blank"
                  >
                    <Typography
                      variant="person_label"
                      sx={{ lineHeight: "100%" }}
                    >
                      смотреть
                    </Typography>
                  </Link>
                </Stack>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {exhibit.showpiece.image ? (
              <CardMedia
                key={"image" + exhibit.id}
                className="one-image"
                component="img"
                sx={{
                  width: "60%",
                  cursor: "pointer",
                  objectFit: "contain",
                }}
                image={exhibit.showpiece.image} //http://qstand.art:8033
                alt={"image" + exhibit.id}
              />) : !isMobile ? <Box sx={{
                border: "2px solid grey",
                width: '60%',
                height: '300px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '8px',
                opacity: 0.4
              }}>изображение не найдено</Box> : <></>
            }
          </Grid>
        </Grid>

        {isMobile ? (
          <PersonModal
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClosePopper}
            currentPerson={currentPerson}
            currentExhTitle={exhibit.title}
          />
        ) : (
          <PersonPopper
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClosePopper}
            currentPerson={currentPerson}
            currentExhTitle={exhibit.title}
          />
        )}
      </Dialog>
    );
  } else {
    return <></>;
  }
}

export default ExhibitModal;
