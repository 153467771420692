import React from "react";
import { FieldArray } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

function DropDownFieldArray({ id, name, label, values, options }) {
  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <Grid item container>
          {values[name].length > 0 && (
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                width: "100%",
                gap: 1,
              }}
              xs={3}
            >
              <Typography variant="advanced_caption"  color="initial">
                {label}
              </Typography>
              {/* <Button
              variant="text"
              sx={{
                textTransform: "none",
                textAlign: "left",
                justifyContent: "flex-start",
                pl: 0,
                color: values[name].length === 0 ? "inherir" : "black",
                cursor: values[name].length === 0 ? "pointer" : "default",
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
                "&:hover": {
                  textDecoration:
                    values[name].length === 0 ? "underline" : "none",
                  backgroundColor: "white",
                },
              }}
              onClick={() =>
                values[name].length === 0
                  ? push({ text: "", isNegative: false })
                  : undefined
              }
            >
              {" "}
              {label}
            </Button> */}
            </Grid>
          )}

          <Grid item key={name + "values"} xs={9}>
            {values[name].map((el, ind) => {
              return (
                <Box
                  key={name + "values" + ind}
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                    justifyContent: "flex-end",
                  }}
                >
                  <FormControlLabel
                    sx={{
                      m: 0,
                      p: 0,
                      "& .MuiTypography-root": {
                        fontSize: "18px",
                      },
                      "& .MuiCheckbox-root": {
                        borderRadius: 0,
                      },
                    }}
                    control={
                      <Checkbox
                      variant="body2"
                        color="primary"
                        checked={el.text === "муж"}
                        onChange={() => {
                          remove(0);
                          push({ text: "муж", isNegative: false });
                        }}
                      />
                    }
                    label={options.male}
                  />
                  <FormControlLabel
                    sx={{
                      m: 0,
                      p: 0,
                      "& .MuiTypography-root": {
                        fontSize: "18px",
                      },
                      "& .MuiCheckbox-root": {
                        borderRadius: 0,
                      },
                    }}
                    control={
                      <Checkbox
                      variant="body2"
                        color="primary"
                        checked={el.text === "жен"}
                        onChange={() => {
                          remove(0);
                          push({ text: "жен", isNegative: false });
                        }}
                      />
                    }
                    label={options.female}
                  />
                  <IconButton
                    onClick={() => remove(ind)}
                    sx={{
                      borderRadius: 0,
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              );
            })}
          </Grid>
        </Grid>
      )}
    </FieldArray>
  );
}

export default DropDownFieldArray;
