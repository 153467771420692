import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { FieldArray } from "formik";
import React from "react";

function FieldArrayPush({
  label,
  variable,
  variant,
  values,
  handleClose = () => {},
}) {
  const labelNameStart = `${variable}Start`;
  const labelNameFinish = `${variable}Finish`;
  return (
    <FieldArray name={variable} key={label + variable + "-field-array-wrapper"}>
      {({ push, remove }) => {
        switch (variant) {
          case "text":
            return (
              <Box
                key={variable + variant + "-one-item"}
                sx={{
                  display: "flex",
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    textAlign: "left",
                    justifyContent: "flex-start",
                    pl: 0,
                    color: "black!important",
                    cursor: "pointer",
                    "& .MuiTouchRipple-root": {
                      display: "none",
                    },
                    "&:hover": {
                      textDecoration: "underline",
                      backgroundColor: "inherit",
                    },
                  }}
                  onClick={() => {
                    push({ text: "", isNegative: false });
                    handleClose();
                  }}
                >
                  {label}
                </Button>
              </Box>
            );
          case "year":
            return (
              <Box
                key={variable + variant + "-one-item"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    textAlign: "left",
                    justifyContent: "flex-start",
                    pl: 0,
                    color: "black!important",
                    cursor: "pointer",
                    "& .MuiTouchRipple-root": {
                      display: "none",
                    },
                    "&:hover": {
                      textDecoration: "underline",
                      backgroundColor: "inherit",
                    },
                  }}
                  onClick={() => {
                    push({ [labelNameStart]: "", [labelNameFinish]: "" });
                    handleClose();
                  }}
                >
                  {label}
                </Button>
              </Box>
            );
          case "dropdown":
            return (
              <Box
                key={variable + variant + "-one-item"}
                sx={{
                  display: "flex",
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    textAlign: "left",
                    justifyContent: "flex-start",
                    pl: 0,
                    color: "black!important",
                    cursor:
                      values[variable].length === 0 ? "pointer" : "default",
                    "& .MuiTouchRipple-root": {
                      display: "none",
                    },
                    "&:hover": {
                      textDecoration:
                        values[variable].length === 0 ? "underline" : "none",
                      backgroundColor: "inherit",
                    },
                  }}
                  onClick={() => {
                    push({ text: "", isNegative: false });
                    handleClose();
                  }}
                >
                  {label}
                </Button>
              </Box>
            );
          case "checkbox":
            return (
              <Box
                key={variable + variant + "-one-item"}
                onClick={() => {
                  push({ text: false, isNegative: false });
                  handleClose();
                }}
              >
                {label}
              </Box>
            );
          default:
            return <></>;
        }
      }}
    </FieldArray>
  );
}

export default FieldArrayPush;
