import { Grid} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import AdvancedToolsBlock from "../Components/Blocks/AdvancedToolsBlock";
import EmptyResults from "../Components/Blocks/EmptyResults";
import ButtonOpenAdvanced from "../Components/Buttons/ButtonOpenAdvanced";
import ResultMobileCards from "../Components/ResultTable/ResultMobileCards";
import ResultTable from "../Components/ResultTable/ResultTable";
import {
  generateResponseData,
  setCurrentFilters,
} from "../Reducers/appReducer";
import {
  genRequestDataSet,
} from "../Utils/ExhibitionsUtils";

function AdvancedSearchPage() {
  const dispatch = useDispatch();
  const abortController = useRef();
  const responseData = useSelector((state) => state.exhi.responseData);
  const showResults = useSelector((state) => state.exhi.showResults);
  const noResults = useSelector((state) => state.exhi.noResults);
  const currentFilters = useSelector((state) => state.exhi.currentFilters);
  let [searchParams, setSearchParams] = useSearchParams();
  const dateIsOldStyle = useSelector((state) => state.exhi.dateIsOldStyle);

  useEffect(() => {
    // разбираем SearchParams и обновляем CurrentFilters
    let arr = [];
    searchParams.forEach((value, key) => {
      const isYear = String(key).indexOf("Year") >= 0;
      const isNegative = String(value).indexOf("!") === 0;
      const text = isNegative ? String(value).replace(/[^!]/g, "") : value;
      arr.push({
        text: isYear ? +text : text,
        label: String(key).replace(/\d{1}$/g, ""),
        isNegative: isNegative,
        id: +String(key)[String(key).length - 1] - 1,
      });
    });
    dispatch(setCurrentFilters(arr));
  }, [searchParams]);

  useEffect(() => {
    if (currentFilters) {
      dispatch(
        generateResponseData({
          obj: genRequestDataSet(currentFilters,dateIsOldStyle),
          abortController: abortController,
        })
      );
    }
  }, [currentFilters,dateIsOldStyle]);

  return (
    <Grid container sx={{ backgroundColor: `white` }}>
      <ButtonOpenAdvanced isClose={true} />
      <Grid item xs={12}>
        <AdvancedToolsBlock />
      </Grid>
      <Grid item xs={12} sx={{ p: { xs: "10px 10px 10px 40px", md: "40px" } }}>
        {noResults && <EmptyResults />}
        {showResults && <ResultTable data={responseData} />}
        {showResults && (
          <ResultMobileCards data={responseData} isAdvanced={true} />
        )}
      </Grid>
    </Grid>
  );
}

export default AdvancedSearchPage;
