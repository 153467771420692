import {
  Box,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWebSiteContent } from "../Reducers/appReducer";
import RawHTML from "../Components/Blocks/RawHtml";
import LoadingProgress from "../Components/Blocks/LoadingProgress";
function AboutPage() {
  const content = useSelector((state) => state.exhi.content);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!content.status) dispatch(fetchWebSiteContent())
  }, [content])

  return (
    <Container
      maxWidth="lg"
      container
      sx={{
        minHeight: "100%",
        m: "10vh auto 10vh auto",
      }}
    >
        {
          !content || content.status !=="fulfilled" && <LoadingProgress/>
        }
        {
          content.data && content.status ==="fulfilled" && content.data.map((block,index)=>{
            return <Grid container rowGap={4}>
            <Grid item xs={12} md={5} lg={4} position={"sticky"}>
              <Typography
                variant="h4"
                sx={{
                  fontSize: { xs: "4rem", sm: "3rem", md: "3rem" },
                }}
              >
                О проекте
              </Typography>
            </Grid>
              <Grid item xs={12} md={12} lg={8} pt={"4rem"} >
              <Typography variant="about_content_text">
                <RawHTML dirtyHTML={block.description}/>
              </Typography>
              </Grid>
            <Grid item xs={12} md={5} lg={4} position={"sticky"}>
              <Typography
                variant="h4"
                sx={{
                  fontSize: { xs: "4rem", sm: "3rem", md: "3rem" },
                }}
              >
                Участники
              </Typography>
            </Grid>
              <Grid item xs={12} md={12} lg={8} pt={"4rem"} >

                <List>
                  {
                    block.members.split(', ').map(member=><ListItem key={member}><Typography variant="about_content_text">{member}</Typography></ListItem>)
                  }
                </List>
              
              </Grid>
            <Grid item xs={12} md={5} lg={4} position={"sticky"}>
              <Typography
                variant="h4"
                sx={{
                  fontSize: { xs: "4rem", sm: "3rem", md: "3rem" },
                }}
              >
                Благодарности
              </Typography>
            </Grid>
              <Grid item xs={12} md={12} lg={8} pt={"4rem"} >
              <Typography variant="about_content_text">
                <RawHTML dirtyHTML={block.gratitude}/>
              </Typography>
              </Grid>

              </Grid>
          })
        }
    </Container>
  );
}

export default AboutPage;
