import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ImageCarousel from "../Components/Carousels/ImageCarousel";

function StartPage() {
  return (
    <Box
      id="wrapper"
      sx={{
        height: {
          xs: "calc(100vh - 60px)",
          sm: "calc(100vh - 105px)",
          md: "calc(100vh - 150px)",
        },
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      
      <ImageCarousel  />
    </Box>
  );
}

export default StartPage;
