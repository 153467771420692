import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import React from "react";

async function feedbackAPIPost(data) {
  fetch(`/api/v1/feedback/send/`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(data),
  });
}

function FeedbackDialog({ open, handleClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      sx={{ zIndex: 12000 }}
    >
      <Formik
        initialValues={{ personName: "", contact: "", message: "" }}
        onSubmit={(values, actions) => {
          const { personName, contact, message } = values;
          if (message.length > 0) {
            feedbackAPIPost({
              name: personName || "",
              contact: contact || "",
              message: message,
            });
          } else {
            alert("Поле сообщения не заполнено");
          }
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <DialogTitle>
              <Typography variant="person_label">Обратная связь</Typography>
            </DialogTitle>

            <DialogContent>
              <DialogContentText>
                Заполните поля контактной информации, чтобы получить ответ
              </DialogContentText>
              <TextField
                autoComplete={false}
                autoFocus
                margin="dense"
                id="personName"
                label="Имя"
                fullWidth
                variant="standard"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                type="text"
                value={props.values.personName}
                name="personName"
              />
              {props.errors.personName && (
                <div id="feedback">{props.errors.personName}</div>
              )}
              <TextField
                autoComplete={false}
                autoFocus
                margin="dense"
                id="contact"
                label="Контактная информация"
                fullWidth
                variant="standard"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                type="text"
                value={props.values.contact}
                name="contact"
              />
              {props.errors.contact && (
                <div id="feedback">{props.errors.contact}</div>
              )}
              <TextField
                autoComplete={false}
                autoFocus
                margin="dense"
                id="message"
                label={null}
                fullWidth
                multiline
                rows={6}
                variant="outlined"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                type="message"
                value={props.values.message}
                name="message"
              />
              {props.errors.message && (
                <div id="feedback">{props.errors.message}</div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} type="reset">
                Очистить
              </Button>
              <Button onClick={handleClose} type="submit">
                Отправить
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

export default FeedbackDialog;
